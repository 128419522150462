import axios from "axios";
import { baseUrl } from "./url";
import { PRODUCTION } from "./constants";
const instance = axios.create({
  baseURL: baseUrl,
  timeout: 1000,
});

instance.interceptors.request.use(
  async (config) => {
    !PRODUCTION &&
      console.log(
        "request\n",
        `url: ${config.url}\nmethod: ${config.method}`,
        config.data,
        config
      );

    config.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*",
    };

    return config;
  },
  (err) => {
    return Promise.reject("Error has occurred from axios.request", err);
  }
);

instance.interceptors.response.use(
  async (response) => {
    !PRODUCTION &&
      console.log(
        "response\n",
        `url: ${response.config.url}\nmethod: ${response.config.method}`,
        response.data
      );

    return response;
  },
  (err) => {
    return Promise.reject("Error has occurred from axios.response", err);
  }
);

export default instance;
