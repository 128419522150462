import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import $ from "jquery";



export default function ScrollTop() {

    const PageLink = useLocation().pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [PageLink]);


  // useEffect(() => {
  //   $('.all_menu_wrap').removeClass('open');
  //   $('#header').removeClass('gnbopen');
  // }, []);

  return (
    null
    );
}