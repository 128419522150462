import React, {useState, useEffect} from "react";
import $ from "jquery";
import Slider,{onReInit} from "react-slick";
import axios from "axios";
import { baseUrl } from "../api/url";

const setCookie = ( name, value, expiredays ) => {
    var todayDate = new Date();  
    todayDate.setDate( todayDate.getDate() + expiredays );  
    document.cookie = name + "=" + escape( value ) + "; path=/; expires=" + todayDate.toGMTString() + ";"  
}

function getCookie(name) { 
	var Found = false 
	var start, end 
	var i = 0 
	 
	while(i <= document.cookie.length) { 
		start = i 
		end = start + name.length 
		 
		if(document.cookie.substring(start, end) == name) { 
			Found = true 
			break 
		} 
		i++ 
	} 
	 
	if(Found == true) {
		start = end + 1
		end = document.cookie.indexOf(";", start) 
		if(end < start) 
			end = document.cookie.length 
		return document.cookie.substring(start, end) 
	} 
	return "" 
}

const PopUpMobile = () => {
    const [popUpMobiles, setPopUpMobiles] = useState([]);
    const [chgPopUpState, setchgPopUpState] = useState(false);
    useEffect(() => {
        
        const handlePopupMobile = async () => {
            await axios.get(`${baseUrl}/api/popup.api.php?device=M`)
                .then((response) => {
                    if (response.data.data.length) {
                        const result = (response.data.data).filter((obj) => getCookie(`main${obj.idx}`) != 'done');
                        setPopUpMobiles(result);
                    } else {
                        setPopUpMobiles('');
                    }
                })
                .catch();
        }
        handlePopupMobile();
    }, [])
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
    const mopopClose = (e) => {
        e.target.closest("#popup_wrap").style.visibility = "hidden";
        document.querySelector("html, body").classList.remove("scrollx");
    }
    const closeWin = (event) => {
        if($(".m_p_1").length==1){
			// 쿠키 설정
            // var pop_idx = $(".slick-active").children(".pop_idx").text();
            let idx = document.querySelector(".slick-active .pop_idx").innerHTML;
			setCookie("main"+idx,"done",1);
			// slick slider remove
			// var i = $(".slick-active").attr("data-slick-index");
			// $(".pop_mo_slide").slick('slickRemove', nowSlide);
			var i = document.querySelector(".slick-active").dataset.idx;
			document.querySelector(".slick-active").remove();
			mopopClose(event);
		}else{
			// 쿠키 설정
            var pop_idx = $(".slick-active").children(".pop_idx").text();
            let idx = document.querySelector(".slick-active .pop_idx").innerHTML;
            
			setCookie("main"+idx,"done",1);

			// slick slider remove
			// var i = $(".slick-active").attr("data-slick-index");
            var i = document.querySelector(".slick-active").dataset.idx;
            // document.querySelector(".slick-active").remove();
			// $(".pop_mo_slide").slick('slickRemove', i);
			// $('.pop_mo_slide').slick('refresh');
			// $('#slick-slider').slick('refresh');
            
            const a = popUpMobiles.filter(obj => obj.idx != idx);
            setPopUpMobiles(a);
		}
    }
    
    
    return (
        <>
            {popUpMobiles.length > 0 ?
                <div id="popup_wrap" style={{ "position": "fixed", "top": "0", "bottom": "0", "left": "0", "right": "0", "width": "100%", "height": "100%", "background": "rgba(0,0,0,0.6)", "zIndex": "10000" }}>
                    <div id="popup_mbox" style={{ "position": "fixed", "width": "100%", "background": "#fff" }}>
                        <div id="pop_moslider">
                            <div className="pop_mo_slide">
                                <Slider {...settings}>
                                {popUpMobiles.map((item, key) => {
                                    return (
                                        <div className="m_p_1" key={key}>
                                            <p className="pop_idx" style={{ "display": "none" }}>{item.idx}</p>
                                            <a href={item.link}>
                                                <img src={item.img} alt="" style={{ "width": "100%" }} />
                                            </a>
                                        </div>
                                    )
                                })}
                                </Slider>
                            </div>
                        </div>
                        <ul id="pop_btn" style={{ "clear": "both", "overflow": "hidden", "borderTop": "1px solid #ddd", "borderBottom": "1px solid #ddd", "textAlign": "center" }}>
                            <li style={{ "float": "left", "width": "50%" }}>
                                <a onClick={(event)=>{
                                    closeWin(event)
                                    }} style={{ "padding": "15px 0", "display": "block", "background": "#efefef", "fontSize": "13px", "fontWeight": "500", "color": "#333", "borderRight": "1px solid #ddd", "cursor":"pointer" }}>이 이벤트 다시 열지 않기</a>
                            </li>
                            <li style={{ "float": "left", "width": "50%" }}>
                                <a onClick={(event)=>{
                                    mopopClose(event)
                                    }} style={{ "padding": "15px 0", "display": "block", "background": "#efefef", "fontSize": "13px", "fontWeight": "500", "color": "#333", "cursor":"pointer"}} >닫기</a>
                            </li>
                        </ul>
                    </div>
                </div>
            : ""}
        </>
    )
}

export default PopUpMobile;