import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { onlyNumer } from "../utils/classReplace";
import { baseUrl } from "../api/url";

const LoginFind = () => {
    const [userName, setUserName] = useState('');
    const [userMobile, setUserMobile] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        const frm = e.target;
        if (!userName) {
            alert("이름을 입력해주세요");
            frm.user_name.focus();
            return;
        }
        if (!userMobile) {
            alert("휴대전화번호를 입력해주세요");
            frm.user_mobile.focus();
            return;
        }
        const frmData = new FormData(frm);
        axios.post(`${baseUrl}/api/findId.api.php`, frmData)
            .then(res => {
                if (res.data.result == "OK") {
                    alert(res.data.msg);
                    document.querySelector("#find_email").value = res.data.id;
                } else {
                    alert(res.data.msg);
                    document.querySelector("#find_email").value = '';
                }
            })
    }
    return(
        <>
        <section className="member find">
            <div className="form_box_wrap">
                <h3 className="form_ttl">아이디/비밀번호 찾기</h3>
                <ul className="member_tab">
                    <li>
                        <Link to="/find/id" className="on">아이디 찾기</Link>
                    </li>
                    <li>
                        <Link to="/find/pw" >비밀번호 찾기</Link>
                    </li>
                </ul>
                <form action="" id="frm1" name="frm1" onSubmit={handleSubmit}>
                    <input type="hidden" name="cert_yn_1" value="N" />
                    <input type="hidden" name="cert_yn_2" value="N" />
                    <input type="hidden" name="find_type" id="find_type" value="id" />
                    <div className="input_box_wrap">
                        <p className="label">이름</p>
                        <div className="input_box">
                            <input type="text" id="user_name" name="user_name" value={userName} placeholder="이름을 입력하세요." onChange={e=>setUserName(e.target.value)} />
                        </div>
                    </div>
                    <div className="input_box_wrap">
                        <p className="label">휴대전화</p>
                        <div className="input_box">
                            <input type="tel" id="user_mobile" className="s_input" name="user_mobile" value={userMobile} maxLength="11" numberonly="true" placeholder="‘-’ 없이 숫자만 입력하세요" onChange={e=>setUserMobile(onlyNumer(e.target.value))} />
                        </div>
                    </div>
                    <div className="button_box">
                        <button type="submit" className="btn_white">아이디(UID) 확인</button>
                    </div>
                    
                    <div className="input_box_wrap id_result">
                        <p className="label">확인 된 아이디(UID)</p>
                        <div className="input_box input_white">
                            <input type="text" name="find_email" id="find_email" value="" readOnly />
                        </div>
                    </div>
                </form>
            </div>
        </section>
        </>
    )
}

export default LoginFind