import React,{useState, useContext,createContext,useReducer, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";



import * as auth from "./auth";





const Logout = () => {
    const initialState = {
        authenticated: false,
        token: null
    }
   
  
    function reducer(state, action) {
        switch(action.type) {
            case 'SET_TOKEN':
                return {...state, token: action.token, authenticated: action.result};
            case 'DELETE_TOKEN':
                return { ...state, token: null, authenticated: false };
            default:
                return state;
        }
    }
    
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        dispatch({
            type: 'DELETE_TOKEN'
        });
        auth.logout();
        navigate("/");
        window.location.reload();
    },[])

    

    return (
       <></>
    )
}

export default Logout;