function onlyNumer(value) {
    return value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
}

function pwRuleChkTxt(value) {
    const regRule = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (!regRule.test(value)) {
        return <div className="password_not" id="password_not" >최소 8자 이상의 영문, 숫자를 사용하세요</div>
    } else {
        return "";
    }
}

function onlyCharChk(value) {
    return value = value.replace(/[0-9.]/g, '').replace(/(\..*)\./g, '');
}

function pwRuleChk(value) {
    const regRule = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    //틀리시 false
    return regRule.test(value)

}

// 한글, 영어, 숫자 replace 함수
function replaceRule1(value) {
    return value.replace(/[^a-z|0-9|ㄱ-ㅎ|가-힣]/gi, '');
}

function handleNickNameChk(value) {
    const regRule = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\*]{2,8}$/;
    return regRule.test(value);
}

function handleNickNameTxt(boolean) {

    if (!boolean) {
        return <div className="password_not">최소 2자 이상 최대 8자 내로 영문, 숫자, 한글을 사용하세요</div>
    } else {
        return '';
    }
}

function handleThousandComma(value) {
    return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
}

function handleUIDMasking(str) {
    const originStr = str;
    return originStr.replace(/(?<=.{6})./gi, "*");
}

export { onlyNumer, pwRuleChkTxt, pwRuleChk, onlyCharChk, replaceRule1, handleNickNameChk, handleThousandComma,  handleUIDMasking}