import React, { useCallback, useRef } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import $ from "jquery";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
function Slide({ list, state, setState, leng, Sliders }) {
  const sliderRef = useRef(null);
  const onClickNext = useCallback((ref) => () => ref.current.slickNext(), []);

  const handleClick = useCallback(() => {
    if (state < leng - 1) {
      setState(state + 1);
    } else {
      setState(0);
    }
  }, [state]);

  return (
    <>
      <StyledSlider className="news_slider" ref={sliderRef} {...settings}>
        {/* <StyledSlider className="slide" {...settings} > */}

        {list.map((element, index) => {
          return (
            <div className="slide" key={index}>
              <Link to={`/newsView/${element.bbs_idx}`}>
                <p className="top flex_b_c">
                  <span>{element.board_name}</span>
                  <span className="date">{element.regdate}</span>
                </p>
                <h3 className="subject">{element.subject}</h3>
                <p className="desc">{element.contents}</p>
              </Link>
            </div>
          );
        })}
      </StyledSlider>
      <div className="slide_handle">
        <button className="setting" onClick={handleClick}></button>
        <button className="next_btn" onClick={onClickNext(sliderRef)}></button>
      </div>
    </>
  );
}

const settings = {
  //autoplay: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  variableWidth: true,
};

const StyledSlider = styled(Slider)``;

export default Slide;
