import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();


 export function setTokenToCookie(token, userId) {
  //console.log(token);
  cookies.set('userToken', token, { sameSite: 'strict' });
  cookies.set('userId', userId, { sameSite: 'strict' });

  
}
export async function getAccessToken() {
  const userToken = cookies.get('userToken');
  const apiUrl = "https://tadmin.mbl2022.com";
  const data = new FormData();
  data.append("token", userToken);

  const abc = await axios.post(`${apiUrl}/api/loginChk.php`, data);
  return abc;
}
/*
export function getAccessToken() {
  
  const userToken = cookies.get('userToken');
  if (userToken) {

    const apiUrl = "https://tadmin.mbl2022.com";
    const data = new FormData();
    data.append("token", userToken);

    let loginState = "NO";
        
    axios.post(`${apiUrl}/api/loginChk.php`, data)
            .then((res) => {
              //return res.data.result;
              
                if (res.data.result == "YES") {
                    //alert(res.data.msg);
                    //alert(res.data.msg);

                    loginState = "YES";
                } else {
                    //alert(res.data.msg);
                    //setCertiChk(false);
                    loginState = "NO";
                }
              
            })

    //console.log(userToken);
    return loginState;
    
  } else {
    
    return "NO";
  }
}
*/


export function logout() {
  console.log('localStorage set logout!');
  window.localStorage.setItem('logout', Date.now());
  cookies.remove('refresh_token');
  cookies.remove("userId");
  cookies.remove("userToken");
}
