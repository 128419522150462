import news01 from "../assets/img/sub/news01.jpg";
import view01 from "../assets/img/sub/view01.jpg";
import view02 from "../assets/img/sub/view02.jpg";

import axios from "axios";

import $ from "jquery";

//import mCustomScrollbar from 'malihu-custom-scrollbar-plugin';
import "jquery-mousewheel";
import "malihu-custom-scrollbar-plugin";
import { useParams } from "react-router-dom";

import { useEffect, useState, components } from "react";

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as newsAction from "./reducer/news/action";

function NewsView() {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.newsState.newsView);
  const { bbs_idx } = useParams();

  useEffect(() => {
    dispatch(newsAction.newsView.request(bbs_idx));
  }, [bbs_idx]);

  return (
    <div id="container" className="news_view">
      <div className="inner">
        <figure className="view_visual">
          <img src={selector.img} alt="2022 MBL SUMMER 거래 규정집" />
        </figure>
        <div className="view_cont">
          <div className="view_head">
            <p className="detail_txt">{selector.regdate}</p>
            <h3 className="subj"> {selector.subject}</h3>
            <p className="sub_subj">{selector.sub_title}</p>
          </div>
          <div
            className="view_desc"
            dangerouslySetInnerHTML={{ __html: selector.contents }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default NewsView;
