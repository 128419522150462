import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import Slide from "./mainSlider";

import top_cont from "../assets/img/sub/top_cont.svg";
import top_cont_mo from "../assets/img/sub/top_cont_mo.jpg";
import { useDispatch, useSelector } from "react-redux";
import * as newsAction from "./reducer/news/action";

import { getDDay } from "../utils/dDayCount";

function TopCont() {
  const menu = [
    "전체",
    "공지사항",
    "뉴스",
    "가이드",
    "수상자 발표",
    "이벤트",
    "기타",
  ];
  const [state, setState] = useState(0);
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.newsState);
  useEffect(() => {
    dispatch(
      newsAction.newsList.request({ url: "api/news.php", bbsCode: "all" })
    );
  }, []);

  const memoList = useMemo(() => {
    let data = selector.newsList;
    if (state === 0) {
      data = selector.newsList;
    } else {
      data = data.filter((element) => element.board_name === menu[state]);
      if (data.length === 0) {
        if (state < menu.length - 1) {
          setState(state + 1);
        } else {
          setState(0);
        }
      }
    }
    return data;
  }, [state, selector.newsList]);

  const dDay = '20220725';

  return (
    <article className="inb flex__c">
      <div className="left flex">
        <a href="http://pf.kakao.com/_xauxiAxj/chat" className="live" target="_blank">
          <img src={top_cont} alt="" className="only_web" />
          <img src={top_cont_mo} alt="" className="only_mo" />
        </a>

        <Link to="/member/agree" className="d-day register">
          <p className="top">대회 참가 접수가 시작되었습니다</p>
          <h2>참가신청</h2>
          <p className="date">~2022.08.28(일) 까지</p>
        </Link>
        <a href="https://www.mexc.com/ko-KR/register?inviteCode=mexc-mblsummer" className="d-day start" target="_blank">
          <p className="top">대회가 시작되었습니다</p>
          <h2>대회진행중</h2>
          <p className="date">~ 2022.09.11(일) 까지</p>
        </a>
      </div>
      <div className="right">
        <Slide
          list={memoList}
          state={state}
          setState={setState}
          leng={menu.length}
          align-item="left"
        />
      </div>
    </article>
  );
}

export default TopCont;
