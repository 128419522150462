import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../api/url";
import { onlyNumer, pwRuleChk, pwRuleChkTxt, onlyCharChk, replaceRule1, handleNickNameChk, handleNickNameTxt } from "../utils/classReplace";
import { useDispatch, useSelector } from "react-redux";
import utilNickNameChk from "../utils/nickNameChk";

const PasswordTxt = () => {
    return (
        <div className="password_not" id="password_not" >신규 비밀번호가 일치하지 않습니다</div>
    )
}

const MemberReg = () => {
    const [userId, setUserId] = useState('');
    const [userIdChk, setUserIdChk] = useState(false);
    const [userIdTxt, setUserIdTxt] = useState('');
    const [userNickName, setUserNickName] = useState('');
    const [userPw, setUserPw] = useState('');
    const [userPwChk, setUserPwChk] = useState('');
    const [userName, setUserName] = useState('');
    const [userMobile, setUserMobile] = useState('');
    const [certiNum, setCertiNum] = useState('');
    const [certiChk, setCertiChk] = useState(false);
    const [passwordChk, setPasswordChk] = useState(false);
    const [nickNameChk, setNickNameChk] = useState(false);
    const [club, setClub] = useState('3천클럽');

    const [passwordChkTxt, setPasswordChkTxt] = useState("");
    const [passwordChkTxt2, setPasswordChkTxt2] = useState("");

    // const [nickNameTxt, setNickNameTxt] = useState('');

    const selector = useSelector((state) => state.newsState);

    let loginChk = "";
    if (selector.loginChk == "YES") {
        loginChk = "YES";
    } else {
        loginChk = "NO";
    }



    const navigate = useNavigate();

    const handleVisible = (e) => {
        e.target.classList.toggle("on");
        if (e.target.classList.contains("on")) {
            e.target.closest("div").querySelector("input").type = 'text';
        } else {
            e.target.closest("div").querySelector("input").type = 'password';
        }
    }

    const handleCertiChk = async (e) => {
        if (!certiNum.length) {
            alert("인증번호를 입력해주세요");
            document.querySelector("#certi_num").focus();
            return;
        }
        const data = new FormData();
        data.append("mobile", userMobile);
        data.append("certinum", certiNum);
        await axios.post(`${baseUrl}/api/certiChk.api.php`, data)
            .then((res) => {
                if (res.data.result == 'OK') {
                    alert(res.data.msg);
                    setCertiChk(true);
                    return;
                } else {
                    alert(res.data.msg);
                    setCertiChk(false);
                    return;
                }
            })
    }
    const handleCertiSend = async (e) => {
        if (userMobile.length < 10) {
            alert("휴대폰번호를 정확히 입력해주세요");
            return;
        }
        const data = new FormData();
        data.append('mobile', userMobile);

        await axios.post(`${baseUrl}/api/certiSend.api.php`, data)
            .then((res) => {
                if (res.data.result == "OK") {
                    alert(res.data.msg);
                    return;
                } else {
                    alert(res.data.msg);
                    setCertiChk(false);
                    return;
                }
            })

    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const frm = e.target;
        if (!userId) {
            alert("UID를 입력해주세요");
            frm.user_id.focus();
            return;
        }
        if (userId.length != 8) {
            alert("UID 8자리를 입력해주세요");
            frm.user_id.focus();
            return;
        }
        if (!userIdChk) {
            alert("이미 사용중인 UID입니다.\r\nUID를 변경해주세요");
            frm.user_id.focus();
            return;
        }

        if (!userPw) {
            alert("비밀번호를 입력해주세요");
            frm.user_pw.focus();
            return;
        }
        if (!userPwChk) {
            alert("비밀번호를 입력해주세요");
            frm.user_pw2.focus();
            return;
        }
        if (!pwRuleChk(userPw)) {
            alert("비밀번호 최소 8자 이상의 영문, 숫자를 사용하세요");
            frm.user_pw.focus();
            return;
        }
        if (!passwordChk) {
            alert("비밀번호가 일치하지않습니다.\r\n다시입력해주세요");
            frm.user_pw.focus();
            return;
        }
        if (!userName) {
            alert("이름을 입력해주세요");
            frm.user_name.focus();
            return;
        }
        if (!userNickName) {
            alert("닉네임을 입력해주세요");
            frm.user_nick.focus();
            return;
        }
        if (!nickNameChk) {
            alert("닉네임 중복확인 해주세요");
            frm.user_nick.focus();
            return;
        }
        if (!userMobile) {
            alert("연락처를 입력해주세요");
            frm.user_mobile.focus();
            return;
        }
        if (!certiNum) {
            alert("인증번호를 입력해주세요");
            frm.certi_num.focus();
            return;
        }
        if (!certiChk) {
            alert("인증확인해주세요");
            frm.certi_num.focus();
            return;
        }

        const frmData = new FormData(frm);
        // frmData.append("user_pw", userPw);
        // frmData.append("user_pw2", userPwChk);

        await axios.post(`${baseUrl}/api/memberReg.api.php`, frmData)
            .then((res) => {
                if (res.data.result == "OK") {
                    alert(res.data.msg);
                    navigate("/");
                } else {
                    alert(res.data.msg);
                    return;
                }
            })
    }

    useEffect(() => {
        if (loginChk == "YES") {
            alert("이미 로그인되었습니다.");
            navigate("/");
        }
    }, [])

    useEffect(() => {

        const uidChkTxt = () => {
            if (!userId) {
                setUserIdTxt("UID를 입력해주세요.");
                setUserIdChk(false);
            }
            if (userId) {
                if (userId.length < 8) {
                    setUserIdTxt("");
                    setUserIdChk(false);
                } else {
                    const idChk = async () => {
                        await axios.get(`${baseUrl}/api/idChk.api.php?userId=${userId}`)
                            .then((res) => {
                                if (res.data.result == 'OK') {
                                    setUserIdChk(true);
                                } else {
                                    setUserIdChk(false);
                                }
                                setUserIdTxt(res.data.msg);
                            })
                    }
                    idChk();
                }
            }
        }
        uidChkTxt();
    }, [userId])

    useEffect(() => {
        setPasswordChkTxt2(pwRuleChkTxt(userPw));
        if (userPw != userPwChk) {
            setPasswordChk(false);
            setPasswordChkTxt(<PasswordTxt />);
        } else {
            setPasswordChk(true);
            setPasswordChkTxt('');
        }
    }, [userPw, userPwChk])

    useEffect(() => {
        // if (!handleNickNameChk(userNickName)) {
            // setNickNameTxt(handleNickNameTxt(handleNickNameChk(userNickName)));
            setNickNameChk(false);
        // }
        // else {
        //     // setNickNameTxt('');
        //     setNickNameChk(true);
        // }
    }, [userNickName])
    console.log(nickNameChk);
    return (
        <>
            <section id="container" className="member join" style={{ "fontSize": "13px", "marginTop": "10px" }}>
                <div className="form_box_wrap">
                    <h3 className="form_ttl">대회 참가신청을 완료해주세요</h3>
                    <form action="member_reg_ok.php" method="post" name="reg_mem_fm" onSubmit={handleSubmit} >
                        <input type="hidden" name="uid_chk" id="uid_chk" value="" />
                        <input type="hidden" name="sms_chk" id="sms_chk" value="" />


                        <div className="input_box_wrap">
                            <div className="label_wrap flex_b_c">
                                <p className="label">UID <span>(MEXC 거래소 UID 숫자 8자리)</span></p>
                                <a href="https://www.mexc.com/ko-KR/register?inviteCode=mexc-mblsummer" target="_blank">MEXC 회원가입</a>
                            </div>
                            <div className="input_box">
                                <input name="user_id" id="user_id" type="text" className="onlynum" value={userId} onChange={(e) => { setUserId(onlyNumer(e.target.value)) }} maxLength="8" autoFocus placeholder="나의 MEXC Global 거래소 계정 UID 숫자 8자리를 입력하세요." />
                                {/* <button type="button" onclick="chk_id();" className="samecheck">중복확인</button> */}
                            </div>
                            <div className="user_id_not" id="user_id_not" style={{ "display": "block", "fontSize": "13px", "marginTop": "10px" }}>{userIdTxt}</div>
                            <div className="msg_box">
                                <span className="color_pt">※ 대회 참가를 위해서는 반드시 MBL 전용 계정으로 회원가입 해야합니다 </span>
                                <span className="color_pt">※ MEXC 회원가입 시 프로모션 코드 ‘mexc-MBLsummer’를 확인하세요</span>
                                <span>※ 프로모션 코드 확인 불가 시 수상이 취소될 수 있습니다</span>
                                <span>※ 대회 참가 계정은 수수료 10% 리베이트 등 수수료 할인 혜택을 제공받습니다.</span>
                            </div>
                        </div>
                        <div className="input_box_wrap">
                            <p className="label">패스워드</p>
                            <div className="input_box input_visible">
                                <input type="password" name="user_pw" id="user_pw" onChange={(e) => setUserPw(e.target.value)} placeholder="최소 8자 이상의 영문, 숫자를 사용하세요." />
                                <button type="button" className="btn_visible" onClick={handleVisible}>숨김표시</button>
                            </div>
                        </div>
                        <div className="input_box_wrap">
                            <p className="label">패스워드 재확인</p>
                            <div className="input_box input_visible">
                                <input type="password" name="user_pw2" id="user_pw2" onChange={(e) => setUserPwChk(e.target.value)} placeholder="위의 패스워드를 동일하게 입력하세요." />
                                <button type="button" className="btn_visible" onClick={handleVisible}>숨김표시</button>
                            </div>
                            {passwordChkTxt}
                            {passwordChkTxt2}
                        </div>
                        <div className="input_box_wrap">
                            <p className="label">참가 클럽</p>
                            <div className="input_box">
                                <select name="club" id="club" style={{ "color": "white" }} onChange={e => setClub(e.target.value)}>
                                    <option value="3천클럽">3천클럽</option>
                                    <option value="1천클럽">1천클럽</option>
                                    <option value="5백클럽">5백클럽</option>
                                    <option value="1백클럽">1백클럽</option>
                                </select>
                            </div>
                        </div>
                        <div className="input_box_wrap">
                            <p className="label">닉네임</p>
                            <div className="input_box">
                                <input type="text" name="user_nick" id="user_nick" value={userNickName} onChange={(e) => setUserNickName(replaceRule1(e.target.value))} placeholder="닉네임을 입력하세요." maxLength="8" className="user_nick_input" required spellCheck="false" />
                                <button type="button" className="btn_submit" onClick={(e) => {
                                    // true / false
                                    utilNickNameChk(e, setNickNameChk);
                                }}>중복확인</button>
                            </div>
                            <div className="msg_box">
                                <span>※ 최소 2글자 이상 최대 8글자</span>
                                <span>※ 영문,한글,숫자만 가능</span>
                            </div>
                        </div>
                        <div className="input_box_wrap input_box_top">
                            <p className="label">이름</p>
                            <div className="input_box input_white">
                                <input type="text" name="user_name" id="user_name" value={userName} onChange={(e) => setUserName(onlyCharChk(e.target.value))} required />
                            </div>
                        </div>

                        <div className="input_box_wrap">
                            <p className="label">휴대전화</p>
                            <div className="input_box input_white">
                                <select name="hpp_location" id="">
                                    <option value="82">대한민국 +82</option>
                                </select>
                            </div>
                            <div className="input_box input_white">
                                <input type="tel" id="user_mobile" name="user_mobile" value={userMobile} onChange={(e) => setUserMobile(onlyNumer(e.target.value))} className="s_input" maxLength="11" numberonly="true" placeholder="전화번호 입력" />
                                <button type="button" onClick={handleCertiSend} className="btn_submit">인증번호 받기</button>
                            </div>
                            <div className="input_box">
                                <input type="tel" name="certi_num" id="certi_num" placeholder="인증번호를 입력하세요." value={certiNum} onChange={(e) => {
                                    setCertiChk(false);
                                    setCertiNum(onlyNumer(e.target.value));
                                }} />
                                <button type="button" onClick={handleCertiChk} className="btn_submit">인증하기</button>
                            </div>
                        </div>
                        <div className="button_box">
                            <button type="submit" className="btn_white">회원가입</button>
                        </div>
                        <div className="help_guide">
                            <p>
                                이미 MBL 계정이 있으신가요?
                                <Link to="/login/login">로그인 하러가기</Link>
                            </p>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default MemberReg;