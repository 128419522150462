import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../api/url";

function setCookie( name, value, expiredays ) {
    var todayDate = new Date();  
	todayDate.setDate( todayDate.getDate() + expiredays );  
	document.cookie = name + "=" + escape( value ) + "; path=/; expires=" + todayDate.toGMTString() + ";"  
}
function getCookie(name) { 
	var Found = false 
	var start, end 
	var i = 0 
	 
	while(i <= document.cookie.length) { 
		start = i 
		end = start + name.length 
		 
		if(document.cookie.substring(start, end) == name) { 
			Found = true 
			break 
		} 
		i++ 
	} 
	 
	if(Found == true) {
		start = end + 1
		end = document.cookie.indexOf(";", start) 
		if(end < start) 
			end = document.cookie.length 
		return document.cookie.substring(start, end) 
	} 
	return "" 
}

const PopUp = () => {
    const [popUpPc, setPopUpPc] = useState([]);
    useEffect(() => {
        const handlePopUpPc = async () => {
            await axios.get(`${baseUrl}/api/popup.api.php?device=P`)
                .then((response) => {
                    if (response.data.data.length) {
                        const result = (response.data.data).filter((obj) => getCookie(`maindivapDiv${obj.idx}`) != 'done');
                        setPopUpPc(result);
                    } else {
                        setPopUpPc('');
                    }
                })
        }
        handlePopUpPc();
    }, [])
    const popUpClose = (e) => {
        e.target.closest(".apDiv").style.visibility = "hidden";
    }
    const closeWin = (e, idx) => {
        e.target.closest(".apDiv").style.visibility = 'hidden';
        setCookie(`maindivapDiv${idx}`,"done",1);
    }
    const handleLink = (url) => {
        window.location.href = url;
    }
    return (
        <>
            {popUpPc.length > 0 ? popUpPc.map((item, key) => {
                return (
                <div className="apDiv" id="apDiv" key={key} style={{ "position": "absolute", "left": `${item.popUpLeft}px`, "top": `${item.popUpTop}px`, "width": `${item.popUpWidth}px`, "height": `${item.popUpHeight}px`, "zIndex": "999999" }} >
                    <table style={{ "bgcolor": "ffffff" }} >
                        <tbody>
                            <tr onClick={() => handleLink(item.link)} style={{ "cursor": "pointer" }} >
                                <td colSpan="2" >
                                    <div>
                                        <a href={item.link}>
                                            <img src={item.img} alt="" style={{ "width": "100%" }} />
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ul id="pop_btn" style={{ "clear": "both", "overflow": "hidden", "borderTop": "1px solid #ddd", "borderBottom": "1px solid #ddd", "textAlign": "center" }}>
                        <li style={{ "float": "left", "width": "50%" }}>
                            <a onClick={(event) => {
                                event.preventDefault();
                                closeWin(event, item.idx);
                            }} style={{ "padding": "15px 0", "display": "block", "background": "#efefef", "fontSize": "13px", "fontWeight": "500", "color": "#333", "borderRight": "1px solid #ddd","cursor":"pointer" }}>오늘 하루 열지 않기</a>
                        </li>
                        <li style={{ "float": "left", "width": "50%" }}>
                            <a onClick={(event) => {
                                event.preventDefault();
                                popUpClose(event);
                            }} style={{ "padding": "15px 0", "display": "block", "background": "#efefef", "fontSize": "13px", "fontWeight": "500", "color": "#333", "cursor":"pointer" }} >닫기</a>
                        </li>
                    </ul>
                </div >
                )
            })
        : ""
        }
        </>
    )
}

export default PopUp