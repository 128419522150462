import logo_f from "../assets/img/common/logo_f.svg";
import { Link } from "react-router-dom";

function Footer(){
    return(
        <footer id="footer">
            <div className="inner">
                <div className="top flex_b_c">
                    <div className="left">
                        <h1 className="foot_logo">
                            <a href="/">
                                <img src={logo_f} alt="footer logo" />
                            </a>
                        </h1>
                        <div className="copy">
                            <p>ⓒ 2022 MBL INSTITUTE.</p>
                        </div>
                    </div>
                    <div className="right">
                        <div className="foot_list">
                            <a href="http://pf.kakao.com/_GtXxob/chat">문의하기</a><small></small>
                            <Link to="/policy">개인정보취급방침</Link>
                        </div>
                    </div>
                </div>


                {/* <div className="bot">
                    <address>
                        <p>법인명: 주식회사 거잔(정보명칭: 세종컨설팅그룹)</p> 
                        <p>사업자등록번호: 824-88-01456</p>
                        <p>대표자 : 한린규</p>
                    </address>
                </div> */}
            </div>
        </footer>
        
        

    )
}


export default Footer;