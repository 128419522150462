import React from "react";
import { Link } from "react-router-dom";

const NewsList = ({list}) => {
  console.log(list)
  return (
    <>
      {list.map((element, index) => {
        return (
          <li key={index}>
            <Link to={`/newsView/${element.bbs_idx}`}>
              <figure>
                <img src={element.img} alt={`${element.subject}`}/>
              </figure>
              <p className="detail_txt point">{element.board_name}</p>
              <h3 className="subj">{element.subject}</h3>
            </Link>
          </li>
        )
      })
      }
    </>
  );
};

export default NewsList;
