import react,{useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../api/url";
import axios from "axios";
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from "react-redux";

const MemberAgree = () => {
    const [chkState, setChkState] = useState(false);
    const navigate = useNavigate();
    const cookies = new Cookies();


    const selector = useSelector((state) => state.newsState);
    //console.log(selector.loginChk);

    let loginChk = "";
    if(selector.loginChk == "YES"){

        loginChk = "YES";
    }else{
        loginChk = "NO";
    }

    


    const goAgree = (event) => {
        const chk = document.querySelector("#agree_all").checked;
        if(!chk){
            alert('개인정보 수집 및 이용, 개인정보활용동의에 동의하셔야 회원가입이 가능합니다.');
            setChkState(false);
        }else{
            setChkState(true);
            navigate("/member/reg");
        }
    }
    const goNoAgree = (event) => {
        document.querySelector("#agree_all").checked = false;
        setChkState(false);
    }
    useEffect(() => {

        if(loginChk=="YES"){
            alert("이미 로그인되었습니다.");
            navigate("/");
        }

        
        const info = async () => {
            await axios.get(`${baseUrl}/api/memberAgree.api.php`)
                .then(res => document.querySelector(".agree_txt").innerHTML = res.data.members);
        }
        info();
    },[])
    return (
        <>
        <section id="container" className="member agree">
            <div className="form_box_wrap">
                <h3 className="form_ttl">이용약관에 동의해주세요</h3>
                <div className="input_box_wrap">
                    <p className="label">개인정보 수집 및 이용 동의 <span>(필수)</span></p>
                    <div className="input_box">
                        <div className="agree_txt">
                            
                        </div>
                    </div>
                </div>
                <div className="agree_box_wrap">
                    <div className="check_item">
                        <input type="checkbox" id="agree_all" name="agree_all" />
                        <label htmlFor="agree_all">개인정보 수집 및 이용, 개인정보활용동의에 모두 동의합니다.</label>
                    </div>
                </div>
                <div className="button_box">
                    <button type="button" onClick={goAgree} className="btn_point">동의합니다</button>
                    <button type="button" onClick={goNoAgree} className="btn_white">동의하지 않습니다</button>
                </div>
                <div className="help_guide">
                    <p>
                        이미 MBL 계정이 있으신가요?
                        <Link to="/login">로그인 하러가기</Link>
                    </p>
                </div>
            </div>
        </section>
        </>
    )
}

export default MemberAgree;