import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../api/url";
import { replaceRule1 } from "../utils/classReplace";
import utilNickNameChk from "../utils/nickNameChk";
import Cookies from 'universal-cookie';
import { useSelector } from "react-redux";

const NickCheck = () => {
    const cookies = new Cookies();
    const [userId, setUserId] = useState('');
    const [nickName, setNickName] = useState('');
    const [nickNameChk, setNickNameChk] = useState(false);
    const navigate = useNavigate();

    const selector = useSelector((state) => state.newsState);
    useEffect(() => {
        if (selector.loginChk == 'NO') {
            // alert("로그인 유저만 수정이 가능한 페이지 입니다.");
            navigate("/", { replace: true });
        }
        if (selector.loginChk == 'YES') {
            setUserId(cookies.get('userId'));
            axios.get(`${baseUrl}/api/nickNameCntChk.api.php?user_id=${userId}`)
                .then(res => {
                    if (res.data.result == 'OK') {
                        //이미 닉네임이 있는 유저임으로 입력페이지 못들어오게 하기
                        navigate("/", { replace: true });
                    }
                })
        }
    }, [selector])

    useEffect(() => {
        setNickNameChk(false);
    }, [nickName])

    const handleSubmit = (e) => {
        e.preventDefault();
        const frm = e.target
        if (!nickName) {
            alert("닉네임을 입력해주세요");
            frm.user_nick.focus();
            return;
        }
        if (!nickNameChk) {
            alert("닉네임 중복확인을 해주세요");
            frm.user_nick.focus();
            return;
        }
        const frmData = new FormData(frm);
        frmData.append('user_id', userId);

        axios.post(`${baseUrl}/api/nickNameUpdate.api.php`, frmData)
            .then(res => {
                if (res.data.result == 'OK') {
                    alert(res.data.msg);
                    navigate('/');
                } else {
                    alert(res.data.msg)
                    return;
                }
            })
    }

    return (
        <>
            <section id="container" className="member join nick_check">
                <div className="form_box_wrap">
                    <form method="post" name="loginForm" id="loginForm" onSubmit={handleSubmit}>
                        <div className="form_box">
                            <h3 className="form_ttl">대회 참가자 닉네임</h3>
                            <div className="input_box">
                                <input type="text" name="user_nick" id="user_nick" value={nickName} onChange={(e) => setNickName(replaceRule1(e.target.value))} placeholder="닉네임을 입력하세요." maxLength="8" className="user_nick_input" required />
                                <button type="button" className="btn_submit" onClick={(e) => {
                                    utilNickNameChk(e, setNickNameChk);
                                }}>중복확인</button>
                            </div>
                            <div className="msg_box">
                                <span>※ 최소 2글자 이상 최대 8글자</span>
                                <span>※ 영문,한글,숫자만 가능</span>
                            </div>
                            <div className="button_box">
                                <button type="submit" className="btn_white">확인</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default NickCheck;