import axios from "axios";
import { baseUrl } from "../api/url";

function utilNickNameChk(e, setState) {
    const nickName = e.target.closest('.input_box').querySelector('input').value;
    let result = false;

    if (nickName.length < 2) {
        alert("최소 2글자 이상 최대 8글자를 입력해주세요");
        setState(false);
        return;
    }

    //axios 회원테이블에서 닉네임 중복없으면 true 반환
    const chkResult = axios.get(`${baseUrl}/api/nickNameChk.api.php?nickName=${nickName}`);
    
    chkResult.then(res => {
        if (res.data.result == 'OK') {
            alert("사용가능한 닉네임 입니다.");
            setState(true);
            return;
        } else {
            alert("이미 사용중인 닉네임 입니다.");
            setState(false);
            return;
        }
    })
}

export default utilNickNameChk;