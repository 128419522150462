import { createReducer } from "../../../helper.js/redux.helper";
import * as newsType from "./type";

const initialState = {
  newsList: [],
  newsView: {},
  loginChk: {},
  isFetching: false,
  errorMessage: "",
};

const newsReducer = createReducer(initialState, {
  [newsType.NEWS_LIST["REQUEST"]]: (state, action) => {
    return {
      ...state,
      isFetching: true,
    };
  },

  [newsType.NEWS_LIST["SUCCESS"]]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      newsList: action.payload,
    };
  },

  [newsType.NEWS_LIST["FAIL"]]: (state, action) => {
    return {
      ...state,
      isFetching: false,
    };
  },

  [newsType.NEWS_VIEW["REQUEST"]]: (state, action) => {
    return {
      ...state,
      isFetching: true,
    };
  },

  [newsType.NEWS_VIEW["SUCCESS"]]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      newsView: action.payload,
      // newsList: action.payload,
    };
  },

  [newsType.NEWS_VIEW["FAIL"]]: (state, action) => {
    return {
      ...state,
      isFetching: false,
    };
  },

  [newsType.LOGIN_CHK["REQUEST"]]: (state, action) => {
    return {
      ...state,
      isFetching: true,
    };
  },


  [newsType.LOGIN_CHK["SUCCESS"]]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      loginChk: action.payload,
      // newsList: action.payload,
    };
  },

  [newsType.LOGIN_CHK["FAIL"]]: (state, action) => {
    return {
      ...state,
      isFetching: false,
    };
  },


});

export default newsReducer;
