import React,{useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../api/url";
import { onlyNumer } from "../utils/classReplace";

const FindPw = () => {
    const navigate = useNavigate();
    const [userId, setUserId] = useState('');
    const [userMobile, setUserMobile] = useState('');
    const [certiNum, setCertiNum] = useState('');
    const [certiChk, setCertiChk] = useState(false);

    const [chgPage, setChgPage] = useState(false);
    const [userPw, setUserPw] = useState({
        pw: "",
        pwChk: "",
    })

    const handleCertiNum = () => {
        // alert("인증번호받기")
        //id, mobile
        // if (!userId) {
        //     alert("아이디를 입력해주세요");
        //     document.querySelector("#user_id").focus();
        //     return;
        // }
        // if (userId.length < 8) {
        //     alert("아이디 8자리를 입력해주세요");
        //     document.querySelector("#user_id").focus();
        //     return;
        // }
        if (!userMobile) {
            alert("전화번호를 입력해주세요");
            document.querySelector("#user_mobile").focus();
            return;
        }
        if (userMobile.length < 10) {
            alert("전화번호를 정확히 입력해주세요");
            document.querySelector("#user_mobile").focus();
            return;
        }
        const frmData = new FormData();
        // frmData.append('user_id', userId);
        frmData.append('user_mobile', userMobile);
        axios.post(`${baseUrl}/api/certiSend2.api.php`, frmData)
            .then(res => {
                // if (res.data.result == "OK") {
                alert(res.data.msg);
                return;
                // }
            })
    }

    const handleCertiChk = () => {
        // alert("인증하기")
        if (!userMobile) {
            alert("전화번호를 입력해주세요");
            document.querySelector("#user_mobile").focus();
            return;
        }
        if (userMobile.length < 10) {
            alert("전화번호를 정확히 입력해주세요");
            document.querySelector("#user_mobile").focus();
            return;
        }
        if (!certiNum) {
            alert("인증번호를 입력해주세요");
            document.querySelector("#certi_num").focus();
            return;
        }
        const frmData = new FormData();
        // frmData.append('user_id', userId);
        frmData.append('user_mobile', userMobile);
        frmData.append('certi_num', certiNum);
        axios.post(`${baseUrl}/api/certiChk2.api.php`, frmData)
            .then(res => {
                if (res.data.result == "OK") {
                    setCertiChk(true);
                } else {
                    setCertiChk(false);
                }
                alert(res.data.msg);
            })
    }

    const handleFindPw = () => {
        // alert("비밀번호찾기");
        // id, mobile, chk(true)
        if (!userId) {
            alert("아이디를 입력해주세요");
            document.querySelector("#user_id").focus();
            return;
        }
        if (userId.length != 8) {
            alert("아이디 8자리를 입력해주세요");
            document.querySelector("#user_id").focus();
            return;
        }
        if (!userMobile) {
            alert("전화번호를 입력해주세요");
            document.querySelector("#user_mobile").focus();
            return;
        }
        if (userMobile.length < 10) {
            alert("전화번호를 정확히 입력해주세요");
            document.querySelector("#user_mobile").focus();
            return;
        }
        if (!certiNum) {
            alert("인증번호를 입력해주세요");
            document.querySelector("#certi_num").focus();
            return;
        }
        if (!certiChk) {
            alert("인증확인 해주세요");
            document.querySelector("#certi_num").focus();
            return;
        }
        const frmData = new FormData();
        frmData.append("user_id", userId);
        frmData.append("user_mobile", userMobile);
        frmData.append("certi_num", certiNum);
        axios.post(`${baseUrl}/api/findPw.api.php`, frmData)
            .then((res) => {
                if (res.data.result == "OK") {
                    setChgPage(true);
                } else {
                    setChgPage(false);
                }
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const frm = e.target;
        if (!userPw.pw) {
            alert("비밀번호를 입력해주세요");
            frm.user_pw.focus();
            return;
        }
        if (userPw.pw.length < 8) {
            alert("최소 8자리 이상 입력해주세요");
            frm.user_pw.focus();
            return;
        }
        if (!userPw.pwChk) {
            alert("비밀번호 재입력 해주세요");
            frm.user_pw2.focus();
            return;
        }
        if (userPw.pw != userPw.pwChk) {
            alert("입력하신 비밀번호가 일치하지 않습니다.");
            return;
        }
        const frmData = new FormData(frm);
        frmData.append('user_id', userId);

        axios.post(`${baseUrl}/api/chgPw.api.php`, frmData)
            .then(res => {
                if (res.data.result == "OK") {
                    alert(res.data.msg);
                    navigate("/");
                } else {
                    alert(res.data.msg);
                    return;
                }
            })
        
    }

    return (
        <>
        {chgPage ? 
        <section className="member find">
            <div className="form_box_wrap">
            <h3 className="form_ttl">아이디/비밀번호 찾기</h3>
                <ul className="member_tab">
                    <li>
                        <Link to="/find/id">아이디 찾기</Link>
                    </li>
                    <li>
                        <Link to="/find/pw" className="on">비밀번호 찾기</Link>
                    </li>
                </ul>            
                <form action="./chg_pw.php" id="frm1" name="frm1" method="post" onSubmit={handleSubmit}>
                    <fieldset>
                        <div className="find_pw_box">
                            <div className="login_form on">
                                <div className="input_box_wrap">
                                    <p className="label">변경하실 비밀번호</p>
                                    <div className="input_box">
                                        <input type="password" id="user_pw" name="user_pw" value={userPw.pw} onChange={(e)=>setUserPw({...userPw, pw: e.target.value})} placeholder="변경하실 최소 8자리 이상의 영문,숫자 혼합 비밀번호를 입력해주세요" />
                                    </div>
                                </div>
                                <div className="input_box_wrap">
                                    <p className="label">비밀번호 재입력</p>
                                    <div className="input_box">
                                        <input type="password" id="user_pw2" name="user_pw2" value={userPw.pwChk} onChange={(e)=>setUserPw({...userPw, pwChk: e.target.value})} placeholder="변경하실 비밀번호를 한번 더 동일하게 입력해주세요" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="button_box">
                            <button type="submit" value="확인" className="btn_white">비밀번호 찾기</button>
                        </div>
                    </fieldset>	 
                </form>	
                
            </div>
        </section>
        :
        <section className="member find">
            <div className="form_box_wrap">
                <h3 className="form_ttl">아이디/비밀번호 찾기</h3>
                <ul className="member_tab">
                    <li>
                        <Link to="/find/id">아이디 찾기</Link>
                    </li>
                    <li>
                        <Link to="/find/pw" className="on">비밀번호 찾기</Link>
                    </li>
                </ul>            
                
                <form action="./new_pw.php" id="frm1" name="frm1" method="post" >
                    <input type="hidden" name="cert_yn_1" value="N" />
                    <input type="hidden" name="cert_yn_2" value="N" />
                    <input type="hidden" name="sms_chk" id="sms_chk" value="" />
                    <fieldset>
                        <div className="find_pw_box">
                            <div className="login_form on">
                                <div className="input_box_wrap">
                                    <p className="label">아이디 <span>(UID 숫자 8자리)</span></p>
                                    <div className="input_box">
                                        <input type="text" id="user_id" name="user_id" value={userId} placeholder="UID 숫자 8자리를 입력하세요." className="onlynum" onChange={e=>setUserId(onlyNumer(e.target.value))} maxLength='8' />
                                    </div>
                                </div>

                                <div className="input_box_wrap">
                                    <p className="label">휴대전화</p>
                                    <div className="input_box input_white">
                                        <select name="" id="">
                                            <option value="82">대한민국 +82</option>
                                        </select>
                                    </div>
                                    <div className="input_box input_white">
                                        <input type="tel" id="user_mobile" className="s_input" name="user_mobile" value={userMobile} maxLength="11" placeholder="전화번호 입력" onChange={e => setUserMobile(onlyNumer(e.target.value))} numberonly="true" />
                                        <button type="button" onClick={handleCertiNum} className="btn_submit">인증번호 받기</button>
                                    </div>
                                    <div className="input_box">
                                        <input type="tel" name="certi_num" id="certi_num" value={certiNum} placeholder="인증번호를 입력하세요." onChange={e=>setCertiNum(onlyNumer(e.target.value))} />
                                        <button type="button" onClick={handleCertiChk} className="btn_submit">인증하기</button>
                                    </div>
                                </div>
                            </div>
                            <div className="login_form">
                                <div className="input_box_wrap">
                                    <p className="label">이메일 <span>(이메일 형식)</span></p>
                                    <div className="input_box">
                                        <input type="text" id="login_phone" name="user_email" placeholder="이메일주소 아이디를 입력하세요." />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="button_box">
                            <button type="button" onClick={handleFindPw} className="btn_white">비밀번호 찾기</button>
                        </div>
                    </fieldset>	 
                </form>	
                
            </div>
        </section>
        }
        </>
    )
}

export default FindPw;