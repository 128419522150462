import $ from "jquery";
import { useEffect, useState } from 'react';
import * as auth from "./auth";
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'universal-cookie';
//import * as newsAction from "./reducer/news/action";


import {
    BrowserRouter,
    Routes,
    Route,
    useLocation,
    useNavigate,
    Link
} from "react-router-dom";

import ImgLogo from "../assets/img/common/logo.svg";
import symbol_color from "../assets/img/common/symbol_color.png";
import quickImg1 from "../assets/img/ico/quick_01.png"
import quickImg2 from "../assets/img/ico/quick_02.png"
import quickImg3 from "../assets/img/ico/quick_03.png"
import axios from "axios";
import { baseUrl } from "../api/url";



function HeadFile() {
    const cookies = new Cookies();
    const navigate = useNavigate();

    function handleLogout() {

        let newToken = "";
        let newUserId = "";


        //console.log('로그 아웃!');
        auth.setTokenToCookie(newToken, newUserId);    // cookie에 refresh_token 저장
        navigate("/logout");

    }

    function hidePop() {

        $('.all_menu_wrap').removeClass('open');
        $('#header').removeClass('gnbopen');
        $('.ham_btn').removeClass('on');


    }



    const [loginState, setLoginState] = useState("");
    //const [loginChk, setLoginChk] = useState("NO");

    const selector = useSelector((state) => state.newsState);
    //console.log(selector.loginChk);

    let loginChk = "";
    const userId = cookies.get('userId');
    if (selector.loginChk == "YES") {
        loginChk = "YES";
    } else {
        loginChk = "NO";
    }
    //console.log(loginChk);

    //auth.getAccessToken().then( res => {setLoginState( res.data.result )  } );

    useEffect(() => {
        // 닉네임 미설정시 닉네임 설정페이지로 이동
        if (selector.loginChk == 'YES') {
            axios.get(`${baseUrl}/api/nickNameCntChk.api.php?user_id=${userId}`)
                .then(res => {
                    // 닉네임 미존재시 페이지 이동
                    if (res.data.result == 'NO') {
                        if (!PageLink.includes('/nickCheck')) {
                            navigate('/nickCheck');
                        }
                    }
                })
        }
    }, [selector])


    useEffect(() => {

        $('.ham_btn').on('click', function () {
            $(this).toggleClass('on');
            $('.all_menu_wrap').toggleClass('open');
            $('#header').toggleClass('gnbopen');
        });





    }, []);

    const handleRankAlert = () => {
        alert('2022 MBL SUMMER 대회 순위는 7월 26일 이후 오픈됩니다.');

        $('.all_menu_wrap').removeClass('open');
        $('#header').removeClass('gnbopen');
        $('.ham_btn').removeClass('on');

    }

    // 현재 위치를 받아서 clasName 에 속성 부여
    const PageLink = useLocation().pathname;
    //console.log(PageLink);

    const handleTopMove = () => {
        document.querySelector("html, body").animate({ "scrollTop": "0" });
    }

    return (
        <>
            <header id="header">
                <div className="inner flex_b_c">
                    <div className="left flex__c">
                        <a href="#!" className="ham_btn"></a>
                        <h1 className="logo">
                            <Link to="/">
                                <img src={ImgLogo} alt="logo" />
                            </Link>
                        </h1>

                        <h1 className="logo symbol">
                            <a href="https://www.mexc.com/ko-KR/register?inviteCode=mexc-mblsummer" target="_blank">
                                <img src={symbol_color} alt="컬러 심볼" />
                            </a>
                        </h1>
                        <nav className="gnb">
                            <ul>
                                <li className={`${PageLink === '/' ? 'on' : ''}`} >             <Link to="/">2022 MBL SUMMER</Link></li>
                                <li className={`${PageLink === '/overview' ? 'on' : ''}`} >     <Link to="/overview">대회개요</Link></li>
                                <li className={`${PageLink === '/participate' ? 'on' : ''}`} >  <Link to="/participate">참가방법</Link></li>
                                <li className={`${PageLink === '/schedule' ? 'on' : ''}`} >     <Link to="/schedule">일정</Link></li>
                                <li className={`${PageLink === '/event' ? 'on' : ''}`} >        <Link to="/event">이벤트</Link></li>
                                <li className={`${PageLink === '/news' || PageLink === '/newsView' ? 'on' : ''}`} >   <Link to="/news">소식</Link></li>
                                {/* <li className={`${PageLink === '/ranking' ? 'on' : ''}`} >   <Link to='/ranking'>랭킹</Link></li> */}
                                <li className={`${PageLink === '/ranking' ? 'on' : ''}`} >   <Link to='' onClick={()=>{handleRankAlert()}}>랭킹</Link></li>
                            </ul>
                        </nav>

                    </div>
                    <div className="right">
                        <div className="mem_link">
                            <a href="https://futures.mexc.com/information/contract_detail?symbol=BTC_USDT" target="_blank" className="link01">
                                선물계약이란?
                            </a>

                            <a href="https://www.mexc.com/ko-KR/register?inviteCode=mexc-mblsummer" target="_blank">MEXC
                                계정생성
                            </a>

                            {loginChk === "NO" ? (
                                <>
                                    <small></small>
                                    <Link to="/login" className="btn_login">
                                        로그인
                                    </Link>
                                    <Link to="/member/agree" className="btn_join full_btn">
                                        참가신청
                                    </Link>
                                </>
                            ) : (

                                <>
                                    <small></small>
                                    <Link to="/mypage">
                                        <span>UID {userId}</span> 님
                                    </Link>
                                    {/* <a href="/login/logout.php" class="btn_logout"> */}
                                    {/* <a href="#!" class="btn_logout" onClick={handleLogout}> */}

                                    <a href="#!" className="btn_logout" onClick={handleLogout}>
                                        로그아웃
                                    </a>
                                </>
                            )}


                            {/* <small></small>
                        <Link to="/mypage">
                            <span>UID 12345678</span> 님
                        </Link>
                        <a href="/login/logout.php" class="btn_logout">
                            로그아웃
                        </a> */}


                        </div>
                    </div>
                </div>
            </header>

            <nav className="all_menu_wrap">
                <div className="all_menu_in">
                    <div className="member_row flex fl_w">
                        <div className="top">
                            <a href="https://futures.mexc.com/information/contract_detail?symbol=BTC_USDT" className="link01">
                                선물계약이란?
                            </a>  <small></small>

                            {loginChk === "NO" ? (
                                <>
                                    <a href="https://www.mexc.com/ko-KR/register?inviteCode=mexc-mblsummer">
                                        MEXC 계정생성
                                    </a>
                                </>
                            ) : (

                                <Link to="/mypage">
                                    <span>UID {userId}</span> 님
                                </Link>

                            )}


                        </div>
                        {loginChk === "NO" ? (
                            <>
                                <div className="bot">
                                    <small className="web"></small>
                                    <Link to="/login">
                                        로그인
                                    </Link><small></small>
                                    <Link to="/member/agree">
                                        회원가입
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="bot"><a href="#!" onClick={handleLogout} className="logout">로그아웃</a></div>
                            </>
                        )
                        }



                    </div>
                    <ul className="menu">
                        <li className={` ${PageLink === '/' ? 'on' : null}`} >             <Link to="/" onClick={hidePop}>2022 MBL SUMMER</Link></li>
                        <li className={` ${PageLink === '/overview' ? 'on' : null}`} >     <Link to="/overview" onClick={hidePop}>대회개요</Link></li>
                        <li className={` ${PageLink === '/participate' ? 'on' : null}`} >  <Link to="/participate" onClick={hidePop}>참가방법</Link></li>
                        <li className={` ${PageLink === '/schedule' ? 'on' : null}`} >     <Link to="/schedule" onClick={hidePop}>일정</Link></li>
                        <li className={` ${PageLink === '/event' ? 'on' : null}`} >        <Link to="/event" onClick={hidePop}>이벤트</Link></li>
                        <li className={` ${PageLink === '/news' || PageLink === '/newsView' ? 'on' : null}`} >   <Link to="/news" onClick={hidePop}>소식</Link></li>
                        <li className={` `} >   <Link to='' onClick={handleRankAlert}>랭킹</Link></li>
                    </ul>
                </div>
            </nav>
            <div className="quick_menu">
                <a href="#" className="go_top" onClick={handleTopMove}><img src={quickImg1} alt="top" /></a>
                <a href="http://pf.kakao.com/_xauxiAxj/chat" target="_blank"><img src={quickImg2} alt="tether KR" /></a>
                <a href="http://pf.kakao.com/_GtXxob/chat" target="_blank"><img src={quickImg3} alt="Kakao talk" /></a>
            </div>
        </>
    )
}


export default HeadFile;