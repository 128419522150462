import React from "react";
import { useNavigate } from "react-router-dom";

const Pagination = ({ totalPage, page, link }) => {
    const navigate = useNavigate();
    page = parseInt(page);
    const startPage = (parseInt((page - 1) / 10) * 10) + 1;
    let endPage = startPage + 9;
    if (endPage >= totalPage) endPage = totalPage;

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pager">
            <ul className="flex_c_c">
                {prvBtn(page)}
                {pageNumbers.map((number, key) => {
                    return (
                        <li key={key} className={`num ${page == number ? 'on' : ''}`}>
                            {page == number ?
                                <button type='button' >{number}</button>
                                :
                                <button type='button' onClick={() => navigate(`${link}${number}`)}>{number}</button>
                            }
                        </li>
                    )
                })}
                {nextBtn(page, totalPage)}
            </ul>
        </div>
    );

    function prvBtn(page) {
        if (page > 1) {
            return (
                <li className="prev">
                    <button type='button' onClick={() => navigate(`${link}${page - 1}`)}>이전으로</button>
                </li>
            )
        } else {
            return (
                <li className="prev">
                    <button type='button'>이전으로</button>
                </li>
            )
        }

    }

    function nextBtn(page, totalPage) {
        if (page < totalPage) {
            return (
                <li className="next">
                    <button type='button' onClick={() => navigate(`${link}${page + 1}`)} >다음으로</button>
                </li>
            )
        } else {
            return (
                <li className="next">
                    <button type='button' >다음으로</button>
                </li>
            )
        }

    }
}


export default Pagination