import overview_img        from "../assets/img/sub/overview_img.png";

import $ from "jquery";


import {useEffect, useState} from 'react';

import {
    BrowserRouter,
    Routes,
    Route,
    Link
  } from "react-router-dom";


function Overview(){

    useEffect(()=>{
        // $('.chk_cancle').click(function(){
        //     $('input[name="category"]').prop("checked", false);
        // })

        


    
    }, []);

    return(
        <div id="container" className="sub overview">
            <figure>
                <img src={overview_img} alt="대회개요" />
            </figure>

            <section className="overview01">
                <h2 className="ttl">대회개요</h2>
                <div className="table_wrap">
                    <table>
                        <tbody>
                            <tr>
                                <th>대회명</th>
                                <td><span>2022 MEXC BITCOIN LEAGUE SUMMER (2022 제1회 비트코인 선물계약 실전투자대회)</span></td>
                            </tr>
                            <tr>
                                <th>참가접수</th>
                                <td><span>2022. 07. 11(월) ~ 2022. 08. 28(일)</span></td>
                            </tr>
                            <tr>
                                <th>대회기간</th>
                                <td><span>2022. 07. 25(월) ~ 2022. 09. 11(일)</span></td>
                            </tr>
                            <tr>
                                <th>수상자 발표</th>
                                <td>
                                    <span>2022. 09. 16(금)</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table_wrap">
                    <table>
                        <tbody>
                            <tr>
                                <th>주최·주관</th>
                                <td><span>MBL INSTITUTE</span></td>
                            </tr>
                            <tr>
                                <th>후원</th>
                                <td><span>MEXC GLOBAL</span></td>
                            </tr>
                            <tr>
                                <th>파트너</th>
                                <td><span>MEXC GLOBAL, 세종컨설팅그룹, 코인판, 코박, 땡글닷컴, 디시인사이드, 비트프로, 코리아퓨쳐스</span></td>
                            </tr>
                            <tr>
                                <th>대회 총 상금</th>
                                <td>
                                    <span>￦ 193,150,000</span>
                                </td>
                            </tr>
                            <tr>
                                <th>대회 거래 플랫폼</th>
                                <td>
                                    <span>MEXC GLOBAL</span>
                                </td>
                            </tr>
                            <tr>
                                <th>거래 상품</th>
                                <td>
                                    <span>BTC/USDT 무기한 선물계약</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>

            <section className="overview02">
                <h2 className="ttl">리그 클럽</h2>
                <p className="detail_txt point">※ 수익금 클럽</p>
                <div className="table_wrap col5">

                    <table>
                        <tbody>
                            <tr>
                                <th>순위 / 예수금</th>
                                <td><strong>3천 클럽</strong></td>
                                <td><strong>1천 클럽</strong></td>
                                <td><strong>5백 클럽</strong></td>
                                <td><strong>1백 클럽</strong></td>
                            </tr>
                            <tr>
                                <th>1위</th>
                                <td><span>5,000 만원</span></td>
                                <td><span>2,000 만원</span></td>
                                <td><span>800 만원</span></td>
                                <td><span>100 만원</span></td>
                            </tr>
                            <tr>
                                <th>2위</th>
                                <td><span>1,500 만원</span></td>
                                <td><span>500 만원</span></td>
                                <td><span>300 만원</span></td>
                                <td><span>50 만원</span></td>
                            </tr>
                            <tr>
                                <th>3위</th>
                                <td><span>1,000 만원</span></td>
                                <td><span>300 만원</span></td>
                                <td><span>200 만원</span></td>
                                <td><span>30 만원</span></td>
                            </tr>
                            <tr>
                                <th>4위</th>
                                <td><span>500 만원</span></td>
                                <td><span>200 만원</span></td>
                                <td><span>100 만원</span></td>
                                <td><span>20 만원</span></td>
                            </tr>
                            <tr>
                                <th>5위</th>
                                <td><span>300 만원</span></td>
                                <td><span>100 만원</span></td>
                                <td><span>50 만원</span></td>
                                <td><span>10 만원</span></td>
                            </tr>
                            <tr>
                                <th>6위</th>
                                <td><span>200 만원</span></td>
                                <td><span>50 만원</span></td>
                                <td><span>30 만원</span></td>
                                <td><span>5 만원</span></td>
                            </tr>
                            <tr>
                                <th>7위</th>
                                <td><span>100 만원</span></td>
                                <td><span>30 만원</span></td>
                                <td><span>20 만원</span></td>
                                <td><span>5 만원</span></td>
                            </tr>
                            <tr>
                                <th>8위 ~ 10위</th>
                                <td><span>50 만원</span></td>
                                <td><span>30 만원</span></td>
                                <td><span>10 만원</span></td>
                                <td><span>5 만원</span></td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="addition_txt">
                        <p>※ 수익금 클럽의 경우, 대회 종료일 기준 대회기간 거래내역의 총 수익금을 기준으로 수상자를 선정합니다.</p>
                        <p>※ 클럽은 예수금(테더 입금액) 기준으로 지정되며, 5천 클럽의 경우 테더 입금액이 한화 기준 5,000만원 이상이 충족해야 합니다. 클럽은 최초 대회 시작일 혹은 최초 대회 참가일
                            기준으로 결정되며, 대회기간 중 예수금의 추가 입금은 무관하나 예수금을 출금할 경우 수상 대상에서 제외될 수 있습니다.</p>
                    </div>

                </div>

                <p className="detail_txt point">※ 수익률 클럽</p>

                <div className="table_wrap col5">
                    <table>
                        <tbody>
                            <tr>
                                <th>순위 / 예수금</th>
                                <td><strong>3천 클럽</strong></td>
                                <td><strong>1천 클럽</strong></td>
                                <td><strong>5백 클럽</strong></td>
                                <td><strong>1백 클럽</strong></td>
                            </tr>
                            <tr>
                                <th>1위</th>
                                <td><span>1,500 만원</span></td>
                                <td><span>500 만원</span></td>
                                <td><span>100 만원</span></td>
                                <td><span>50 만원</span></td>
                            </tr>

                        </tbody>
                    </table>
                    <div className="addition_txt">
                        <p>※ 수익률 클럽의 경우, 대회 종료일 기준 최초 예수금 및 대회 기간 중 추가 예수금 입금액 대비 수익률을 기준으로 수상자를 선정합니다.</p>
                        <p>※ 클럽은 예수금(테더 입금액) 기준으로 지정되며, 5천 클럽의 경우 테더 입금액이 한화 기준 5,000만원 이상이 충족해야 합니다. 클럽은 최초 대회 시작일 혹은 최초 대회 참가일
                            기준으로 결정되며, 대회기간 중 예수금의 추가 입금은 무관하나 예수금을 출금할 경우 수상 대상에서 제외될 수 있습니다.</p>
                    </div>
                </div>
            </section>

            <section className="overview03">
                <h2 className="ttl">이벤트</h2>
                <p className="detail_txt point">Event 1. 주간 거래량 이벤트</p>
                <div className="table_wrap td_full_bg col3">
                    <table>
                        <tbody>
                            <tr>
                                <th>대상자</th>
                                <td colSpan="4" className="anth_bg">2022 MEXC BITCOIN LEAGUE SUMMER 모든 참가자</td>
                            </tr>
                            <tr>
                                <th rowSpan="4">이벤트 내용</th>
                                <td>주간 거래량 1,000,000 USDT 이상</td>
                                <td colSpan="3">신세계 상품권 10만원</td>
                            </tr>
                            <tr>
                                <td>주간 거래량 5,000,000 USDT 이상</td>
                                <td colSpan="3">신세계 상품권 30만원</td>
                            </tr>
                            <tr>
                                <td>주간 거래량 10,000,000 USDT 이상</td>
                                <td colSpan="3">100만원</td>
                            </tr>
                            <tr>
                                <td>주간 거래량 30,000,000 USDT 이상</td>
                                <td colSpan="3">200만원</td>
                            </tr>
                            <tr>
                                <th>수상조건</th>
                                <td colSpan="4" className="anth_bg">1주차 ~ 7주차까지 주차별 중복 수상 가능, 주차 내 중복수상 불가</td>
                            </tr>
                            <tr>
                                <th rowSpan="7">수상자 발표</th>
                                <td>1주차 수상자 발표</td>
                                <td colSpan="3">08. 03(수)</td>
                            </tr>
                            <tr>
                                <td>2주차 수상자 발표</td>
                                <td colSpan="3">08. 10(수)</td>
                            </tr>
                            <tr>
                                <td>3주차 수상자 발표</td>
                                <td colSpan="3">08. 17(수)</td>
                            </tr>
                            <tr>
                                <td>4주차 수상자 발표</td>
                                <td colSpan="3">08. 24(수)</td>
                            </tr>
                            <tr>
                                <td>5주차 수상자 발표</td>
                                <td colSpan="3">08. 31(수)</td>
                            </tr>
                            <tr>
                                <td>6주차 수상자 발표</td>
                                <td colSpan="3">09. 07(수)</td>
                            </tr>
                            <tr>
                                <td>7주차 수상자 발표</td>
                                <td colSpan="3">09. 14(수)</td>
                            </tr>
                            <tr>
                                <th>기타</th>
                                <td colSpan="4" className="anth_bg">5만원 초과 제세공과금(4.4%) 고객 부담</td>
                            </tr>


                        </tbody>
                    </table>

                </div>

                <p className="detail_txt point">Event 2. 공유하기 이벤트</p>

                <div className="table_wrap">
                    <table>
                        <tbody>
                            <tr>
                                <th>대상자</th>
                                <td>누구나 참여가능 (대회 미 참가자 참여 가능)</td>
                            </tr>
                            <tr>
                                <th>이벤트 내용</th>
                                <td>블로그, 트위터, 페이스북, 인스타그램 등 공유를 원하는 자신의 SNS로 대회 홍보게시글 공유하기 (대회 홈페이지 이벤트 페이지 참조)</td>
                            </tr>
                            <tr>
                                <th>이벤트 경품</th>
                                <td>공유한 고객 중 총 10명을 추첨하여 신세계 상품권 100만원 증정 (총 1,000만원 상당)</td>
                            </tr>
                            <tr>
                                <th>이벤트 기간</th>
                                <td>2022. 07. 11(월) ~ 2022. 08. 31(수)</td>
                            </tr>
                            <tr>
                                <th>수상자 발표</th>
                                <td>2022. 09. 14(수)</td>
                            </tr>
                            <tr>
                                <th>기타</th>
                                <td>각 SNS 종류별로 1회씩 총 4회 추첨 기회 제공, 대회 미 참가자도 참여 가능, 대회기간 중 공유한 포스트 삭제 시 수상 취소, 5만원 초과 제세공과금(22%)
                                    고객 부담</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </section>

            <section className="partner_sect">
                <div className="inner">
                        <h3>PARTNER</h3>
                        <div className="partner_wrap flex_b_c fl_w">
                    <a href="#!">mbl institute</a>
                    <a href="https://www.mexc.com/ko-KR/register?inviteCode=mexc-mblsummer" target="_blank">MEXC Global</a>
                    <a href="https://t.me/tetherkr" target="_blank" >TETHER KR</a>
                    <a href="https://coinpan.com/" target="_blank"  className="white">코인판</a>
                    <a href="https://cobak.co.kr/" target="_blank"  className="white">코박</a>
                    <a href="https://www.ddengle.com/" target="_blank" >땡글닷컴</a>
                    <a href="https://www.youtube.com/channel/UCeFxGzT7D_Lx_AXGVn9RFKQ" target="_blank" >bitpro</a>
                    <a href="https://www.youtube.com/channel/UCjphUpdIBR3SaQjFviL251A" target="_blank" >코리아퓨쳐스</a>
                    <a href="https://uwal.co.kr/main/main.php" target="_blank" >uwal</a>
                    <a href="http://scg.life/" target="_blank" >세종컨설팅그룹</a>
                </div>
                </div>
            </section>


            <section className="overview04">
                <h2 className="ttl">대회 및 거래 유의사항</h2>
                <ul className="list">
                    <li>
                        <p>본 대회 참가자는 가상자산 선물계약&lt;BTC/USDT 무기한 선물계약&gt;에 대하여 MEXC Global로부터 충분한 설명을 들을 권리가 있으며, 투자전 홈페이지에 명시된
                            '선물계약이란?' 등을 반드시 읽어보시기 바랍니다.</p>
                    </li>
                    <li>
                        <p>본 대회 홈페이지에 기재되어 있는 프로모션 코드(혹은 MEXC Global 거래소 회원가입 링크)를 통해 MEXC Global 거래소에 신규 가입한 참가자에 한하여 대회 참가가
                            인정됩니다.</p>
                    </li>
                    <li>
                        <p>본 대회는 해외 가상자산 거래소 MEXC Global 플랫폼에서 이루어지는 대회로, 대회 참가자는 MEXC Global 거래소에 직접 회원가입 한 후 가상자산
                            선물계약&lt;BTC/USDT 무기한 선물계약&gt;을 진행합니다.</p>
                    </li>
                    <li>
                        <p>가상자산 선물계약&lt;BTC/USDT 무기한 선물계약&gt;은 자산가격 변동, 환율 변동 등에 따라 투자원금의 손실(0~100%)이 발생할 수 있으며, 그 손실은 투자자에게
                            귀속됩니다.</p>
                    </li>
                    <li>
                        <p>가상자산 선물계약&lt;BTC/USDT 무기한 선물계약&gt; 선물 계좌 잔고가 유지증거금(테더, USD T)에 미달하는 경우 계약이 강제 청산될 수 있으니 유의하시기 바랍니다.
                        </p>
                    </li>
                    <li>
                        <p>가상자산 선물계약&lt;BTC/USDT 무기한 선물계약&gt;을 진행하는 과정에서 '격리(Isolated)'로 계약 포지션을 오픈할 경우 해당 포지션에 사용된 유지증거금만으로
                            포지션이 운용되며, '교차(Cross)'로 계약 포지션을 오픈할 경우 자신의 선물 계좌 유지증거금까지 사용됩니다. 이때 선물 계좌 잔고가 유지증거금에 미달하는 경우 계약이 강제
                            청산될 수 있습니다.</p>
                    </li>
                    <li>
                        <p>MEXC Global 거래소의 선물 수수료는 지정가 0.02%, 시장가 0.06%이며, 본 대회 프로모션 코드를 이용해 회원가입 할 경우 수수료 10% 리워드 정책에 따라 지정가
                            0.018%, 시장가 0.036% 개념으로 거래 가능합니다. 기타 수수료 관련된 사항은 MEXC Global 홈페이지 등을 참고하시기 바랍니다.</p>
                    </li>
                    <li>
                        <p>일중매매는 거래전략 등 투자에 관한 지식과 경험을 필요로 하며, 단기간 내 많은 손실을 입을 수 있습니다.</p>
                    </li>
                    <li>
                        <p>허수, 가장매매 등 공정거래질서 저해행위를 삼가하여 주시기 바라며, 수상자로 결정되었더라도 불공정한 매매에 해당될 경우 수상자격이 박탈될 수 있습니다.</p>
                    </li>
                    <li>
                        <p>수익률 및 수익금이 동일한 경우 거래금액이 더 높은 고객이 우선 선정됩니다.</p>
                    </li>
                    <li>
                        <p>마이너스 수익률은 수상자격에서 제외됩니다.</p>
                    </li>
                    <li>
                        <p>대회기간 거래량(레버리지를 포함한 대회 기간 모든 거래량의 합산)이 유지증거금의 3,000% 미만인 고객은 시상에서 제외합니다.</p>
                    </li>
                    <li>
                        <p>잦은 거래는 수수료 과다발생을 유발하여 수익 실현 기회를 제한할 수 있습니다.</p>
                    </li>
                    <li>
                        <p>수익률/수익금 리그의 제세공과금은 4.4%이며, 이는 고객 부담입니다.</p>
                    </li>
                    <li>
                        <p>실전투자대회는 리그별로 1인당 1계좌로만 참가가 가능하며, 대회 시작 후 취소한 경우 재신청은 불가합니다.</p>
                    </li>
                    <li>
                        <p>천재지변, 전산장애 등으로 대회진행에 심각한 장애 상황이 발생할 경우 대회를 중단하거나 대회 기간을 단축할 수 있습니다.</p>
                    </li>
                    <li>
                        <p>본 대회 참가신청을 위해 홈페이지를 통해 회원가입할 때 취급하는 개인정보는 개인정보 취급방침에 따라 처리하며, 마케팅 활용 등에 사용될 수 있습니다. 개인 정보는 최대 1년까지
                            저장하며, 이후에는 지체없이 본 대회 주최측에서 정한 일정한 방법으로 일괄 파기합니다.</p>
                    </li>
                    <li>
                        <p>상금은 대회 기간 종료 후 사전에 고지한 일자에 지급되며, 대회 주최측의 사정에 따라 일정 기간 연기될 수 있습니다.</p>
                    </li>
                    <li>
                        <p>수상자가 참가신청서에 기입한 연락처로 1개월 이상 연락되지 않을 경우 수상이 취소될 수 있습니다.</p>
                    </li>
                    <li>
                        <p>참가신청을 하지 않고 거래한 내역에 대해서는 대회기간에 소급 적용되지 않습니다.</p>
                    </li>
                    <li>
                        <p>주최측 사정에 따라 대회 상금은 현금 지급(계좌이체) 외 다양한 방법으로 지급될 수 있습니다. *대회 상금은 테더(USDT)로 지급될 수 있습니다.</p>
                    </li>
                </ul>

            </section>
            

            <section className="overview05">
                <h2 className="ttl">이벤트 안내</h2>
                <div className="list_wrap">
                    <p className="detail_txt point">이벤트 공통</p>
                    <ul className="list">
                        <li>
                            <p>본 이벤트는 2022 MBL SUMMER 참가 신청 시 자동 참가됩니다.</p>
                        </li>
                        <li>
                            <p>모바일 상품권의 경우 2022 MBL SUMMER 참가 신청 시 입력한 휴대폰 번호로 발송될 예정이며, 모바일 상품권의 교환기간 연장 또는 등록된 핸드폰번호 외의 다른번호로 재발송
                                및 고객 휴대폰의 착신정지 또는 문자 스팸처리 등으로 인한 미수신에 따른 재발송은 불가능하오니 유의하시길 바랍니다.</p>
                        </li>
                        <li>
                            <p>원활한 상금 및 경품 지급, 금융사기 예방을 위하여 고객정보는 반드시 최신정보로 관리하시기 바랍니다.</p>
                        </li>
                    </ul>
                </div>
                <div className="list_wrap">
                    <p className="detail_txt point">거래량 이벤트</p>
                    <ul className="list">
                        <li>
                            <p>5만원 초과 상금 및 경품에 대한 제세공과금(4.4%)은 고객 부담입니다.</p>
                        </li>
                        <li>
                            <p>대회에 참가 신청한 계정으로 대회기간 내 주차별 거래량만 합산합니다. (대회 참가 취소 시 당첨 대상자에서 제외)</p>
                        </li>
                        <li>
                            <p>당첨자 발표는 매주 수요일 대회 홈페이지 공지사항에 게시 후 실전투자대회 참가 시 입력한 핸드폰번호로 모바일 쿠폰이 발송될 예정이며, 현금은 대회 주최측에서 직접적으로 연락드릴 예정이며, 고객 휴대폰의 착신정지 또는 문자 스팸처리 등으로 인한 미수신에 따른 재발송은 불가능하오니 유의하시길 바랍니다.</p>
                        </li>
                        <li>
                            <p>주차별 중복 당첨 가능하오나, 주차 내 중복 당첨은 불가합니다.</p>
                        </li>
                        <li>
                            <p>상금은 불공정 매매 여부 확인 후 지급되며, 허수성거래, 가장통정성거래 등 불공정거래(공정거래질서 저해행위 포함)로 판단되는 주문이 있는 경우 상금 및 경품 수령대상에서 제외됩니다.</p>
                        </li>
                                    </ul>
                                </div>
                                <div className="list_wrap">
                                    <p className="detail_txt point">공유하기 이벤트</p>
                                    <ul className="list">
                        <li>
                            <p>5만원 초과 상금 및 경품에 대한 제세공과금(22%)은 고객 부담입니다.</p>
                        </li>
                        <li>
                            <p>ex) 상금 및 경품 수령시 실제 혜택 : 경품금액 1백만원일 경우,</p>
                        </li>
                        <li>
                            <p>경품(1백만원)-제세공과금(1백만원*22%=22만원)=실제혜택(78만원)</p>
                        </li>
                        <li>
                            <p>본 이벤트 상금 및 경품은 이벤트 참여 시 입력한 연락처로 당첨자 발표를 하며, 발표 후 1개월 이내에 정보 오류 등으로 연락되지 않을 경우 경품 지급이 취소될 수 있습니다.</p>
                        </li>
                        <li>
                            <p>2022 MBL SUMMER 공유한 모든 회원 중 10명을 추첨하여 상금 및 경품을 증정합니다. (이벤트 페이지의 SNS 버튼을 통해 공유 및 대회기간 중 게시물 유지한 자에 한함)</p>
                        </li>
                        <li>
                            <p>총 1차례 추첨을 진행합니다.</p>
                        </li>
                    </ul>
                </div>

            </section>



        </div>
        
   
    )
}




export default Overview;