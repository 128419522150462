import {
  all,
  call,
  put,
  take,
  fork,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import axios from "axios";
import instance from "../../../api";
import { PRODUCTION } from "../../../api/constants";
import * as newsType from "./type";
import * as newsAction from "./action";
import { baseUrl } from "../../../api/url";

function* newsListSaga({ type, payload }) {
  try {
    const response = yield call(
      axios.get,
      `${baseUrl}/${payload.url}?bbsCode=${payload.bbsCode}`
    );

    if (response.status === 200) {
      yield put(newsAction.newsList.success(response.data));
    } else {
      yield put(newsAction.newsList.fail("알 수 없는 오류가 발생하였습니다."));
    }
  } catch (error) {
    !PRODUCTION && console.log("fetchSearchSaga has error occurred", error);
    yield put(
      newsAction.newsList.fail(
        "서버와 통신을 할 수 없습니다.\n잠시후 다시 시도해 주시거나 앱을 종료후 재시작하십시오."
      )
    );
  }
}

function* loginChkSaga({ type, payload }){
  try {
    

    const response = yield call(
      axios.get,
      `${baseUrl}/api/loginChk.php?token=${payload.token}`
    );

    if (response.status === 200) {
      // yield put(newsAction.loginChk.success(response.data.result));
      yield put(newsAction.loginChk.success(response.data.result));
    } else {
      yield put(newsAction.loginChk.fail("알 수 없는 오류가 발생하였습니다."));
    }


  }catch (error){
    !PRODUCTION && console.log("fetchSearchSaga has error occurred", error);
    yield put(
      newsAction.loginChk.fail(
        "서버와 통신을 할 수 없습니다.\n잠시후 다시 시도해 주시거나 앱을 종료후 재시작하십시오."
      )
    );

  }

}

function* newsViewSaga({ type, payload }) {
  try {
    const response = yield call(
      axios.get,
      `${baseUrl}/api/newsview.php?bbs_idx=${payload}`
    );

    if (response.status === 200) {
      yield put(newsAction.newsView.success(response.data[0]));
    } else {
      yield put(newsAction.newsView.fail("알 수 없는 오류가 발생하였습니다."));
    }
  } catch (error) {
    !PRODUCTION && console.log("fetchSearchSaga has error occurred", error);
    yield put(
      newsAction.newsView.fail(
        "서버와 통신을 할 수 없습니다.\n잠시후 다시 시도해 주시거나 앱을 종료후 재시작하십시오."
      )
    );
  }
}

export function* newsSaga() {
  yield all([
    takeEvery(newsType.NEWS_LIST["REQUEST"], newsListSaga),

    takeEvery(newsType.NEWS_VIEW["REQUEST"], newsViewSaga),

    takeEvery(newsType.LOGIN_CHK["REQUEST"], loginChkSaga),
  ]);
}
