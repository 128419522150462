import overview_img        from "../assets/img/sub/overview_img.png";

import $ from "jquery";


import {useEffect, useState} from 'react';

import {
    BrowserRouter,
    Routes,
    Route,
    Link
  } from "react-router-dom";


function Participate(){

    useEffect(()=>{
        // $('.chk_cancle').click(function(){
        //     $('input[name="category"]').prop("checked", false);
        // })

        


    
    }, []);

    return(
        <div id="container" className="sub participage">
            <section className="noti_sect">
                <h2 className="ttl">
                    중요공지
                </h2>
                <p className="detail_txt">2022 MBL SUMMER에 참가하기 위해서는 반드시 MEXC Global 계정이 필요합니다.
                    <br className="only_web" />
                    <span className="point bold">
                        MBL Summer는 MEXC Global 대회 거래 계정을 별도로 지원하며, 반드시 아래 링크를 통해 회원가입을 진행해야 합니다.
                    </span>
                </p>

                <div className="table_wrap">
                    <table>
                        <tbody>
                            <tr>
                                <th className="point">회원가입 링크</th>
                                <td><a href="https://www.mexc.com/ko-KR/register?inviteCode=mexc-MBLsummer" target="_blank">https://www.mexc.com/ko-KR/register?inviteCode=mexc-MBLsummer <span className="link_box point"></span></a></td>
                            </tr>
                            <tr>
                                <th className="point">나의 프로모션 코드</th>
                                <td><span>mexc-MBLsummer</span></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="addition_txt">
                        <p>※ <span className="point bold">반드시 위 링크 및 대회 프로모션 코드(mexc-MBLsummer)계정을 생성해야만 대회 참가가 가능</span>합니다.</p>
                        <p>※ 대회 프로모션 코드가 아닌 외부 코드로 가입하여 대회에 참가신청할 경우 대회 수상이 불가능할 수 있으며, 반드시 위 프로모션 코드를 참고하시기 바랍니다.</p>
                        <p>※ 기존에 MEXC Global 계정이 있으실 경우, 위 링크를 통해 추가 회원가입을 진행하시기 바랍니다.</p>
                    </div>
                </div>

            </section>

            <section className="how_sect">
                <h2 className="ttl">참가방법</h2>
                <p className="detail_txt">2022 MBL SUMMER는 대회 참가자의 UID를 별도로 분류하여 대회 기간 중 거래성적을 실시간으로 조회하고 최종적으로 수상자를 선정하는 데 사용합니다.<br className="only_web" />
                    반드시 아래의 참가방법 순서를 지켜서 대회 참가신청서를 제출하여 주시기 바랍니다.
                </p>
                <div className="table_wrap th2">
                    <table>
                        <tbody>
                            <tr>
                                <th>1</th>
                                <th>MEXC Global 회원가입</th>
                                <td><span>위 <a href="https://www.mexc.com/ko-KR/register?inviteCode=mexc-mblsummer" target="_blank" className="point">회원가입 링크<span className="link_box point"></span></a> 를 통해 거래소 회원가입을 진행합니다. 이때 반드시 프로모션 코드 mexc-MBLsummer를 확인하시기 바랍니다</span></td>
                            </tr>
                            
                            <tr>
                                <th>2</th>
                                <th>대회 참가신청</th>
                                <td><span><a href="/member/agree" className="point" target="_blank">대회 참가신청 페이지<span className="link_box point"></span></a> 에 참가자 본인의 개인정보와 위 대회 프로모션 코드를 통해 가입한 MEXC Global 거래소 계정의 UID 번호를 제출합니다</span></td>
                            </tr>
                            <tr>
                                <th>3</th>
                                <th>KYC 인증</th>
                                <td><span>국내 거래소 및 해외 거래소 간의 원활한 입·출금을 위해 MEXC Global 거래소의 KYC 인증을 진행합니다</span></td>
                            </tr>
                            <tr>
                                <th>4</th>
                                <th>예수금 입금</th>
                            <td><span>아래의 예수금 입금 가이드를 통해 비트코인 선물 거래를 위한 테더(USDT)를 입금합니다. <a href="http://pf.kakao.com/_xauxiAxj/chat" className="point" target="_blank">TETHER KR 간편 입출금 문의하기<span className="link_box point"></span></a> </span></td>
                            </tr>
                            <tr>
                                <th>5</th>
                                <th>대회 시작</th>
                                <td><span>2022년 7월 25일(월) 대회 시작일이 되면 MEXC Global에서 지원하는 <a href="https://futures.mexc.com/exchange?type=linear_swap" className="point" target="_blank">BTC/USDT 무기한 선물계약<span className="link_box point"></span></a> 을 통해 매매를 시작합니다.</span></td>
                            </tr>
                        
                        </tbody>
                    </table>
                </div>

            </section>

            <section>
                <p className="detail_txt">※ 대회 참가 가이드</p>
                <div className="table_wrap th2">
                    <table>
                        <tbody>
                            <tr>
                                <th><a href="https://drive.google.com/file/d/148t4SWmwJ0B7quA0hLi9_WwFZo4bfiOr/view?usp=sharing" className="link_box" target="_blank"></a></th>
                                <th>MEXC KYC 인증 가이드</th>
                                <td>MEXC Global 거래소에서 KYC 인증하는 방법</td>
                            </tr>
                            <tr>
                                <th><a href="https://drive.google.com/file/d/158vz-Mamt6Q_Y53sLKbJZf4LcDpVMnMb/view?usp=sharing" className="link_box" target="_blank"></a></th>
                                <th>MEXC 입금 가이드</th>
                                <td>MEXC Global 거래소로 테더를 입금하는 방법</td>
                            </tr>
                            <tr>
                                <th><a href="https://futures.mexc.com/information/contract_detail?symbol=BTC_USDT" className="link_box" target="_blank"></a></th>
                                <th>선물계약이란?</th>
                                <td>MEXC Global 거래소가 지원하는 선물 계약에 대한 세부내용</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>


        </div>
        
   
    )
}




export default Participate;