import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import Cookies from 'universal-cookie';
import { baseUrl } from "../api/url";

import { handleUIDMasking } from "../utils/classReplace"

// 닉네임미설정시 출력
const nickTmpName = "닉네임미설정";
// 리스트 클래스 배열
const rankTopArr = ['r_1st', 'r_2rd', 'r_3nd'];

const RankingClub = () => {
    // 로그인 확인 //
    const cookies = new Cookies();
    const navigate = useNavigate();
    const selector = useSelector((state) => state.newsState);
    const userId = cookies.get('userId');
    // 로그인 확인 //

    const [clubType, setClubType] = useState("3천클럽");
    const [clubRankList, setClubRankList] = useState({
        profitsList: {
            top: [],
            other: [],
        },
        percentList: {
            top: [],
            other: [],
        }
    });

    // 검색시 출력되는 정보
    const [searchUID, setSearchUID] = useState('');
    const [searchOriginUID, setSearchOriginUID] = useState('');
    const [searchTxt, setSearchTxt] = useState('');

    const chkClubType = useMemo(() => {
        return clubType;
    }, [clubType])

    useEffect(() => {
        // 로그인 유저만 사용가능하게
        if (selector.loginChk !== 'YES' && !userId) {
            alert("회원만 사용이 가능한 페이지 입니다.");
            navigate("/login");
        } 

        axios.get(`${baseUrl}/api/rankingClub.ajax.php?type=${clubType}`)
            .then(res => {
                setClubRankList(res.data)
            })
    }, [chkClubType])

    const handleClick = (e) => {
        setClubType(e.target.value);
    }

    const handleBtnClick = () => {
        if (searchUID != searchOriginUID) {
            setSearchOriginUID(searchUID);
        }
    }

    useEffect(() => {
        if (searchOriginUID != '') {
            axios.get(`${baseUrl}/api/rankingClubSearch.ajax.php?UID=${searchOriginUID}`)
                .then(res => {
                    if (res.data.cnt > 0) {
                        const msg = `${searchOriginUID}님은 [${res.data.club}]으로 신청하셨으며, 수익률 기준 ${res.data.rank_percent}등, 수익금 기준 ${res.data.rank_profits}등입니다.`;
                        setSearchTxt(msg);
                    } else {
                        alert("등록하신 UID의 랭킹 정보가 없습니다.");
                        setSearchTxt('');
                    }
            })
        }
    },[searchOriginUID])

    return (
        <div id="container" className="sub ranking_container">
            <section className="ranking_sect">
                <div className="rank_top">
                    <div className="inner">
                        <h2 className="ttl">
                            RANKING
                        </h2>
                        <p className="detail_txt">2022 MBL SUMMER</p>
                        <div className="search_box">
                            <form action="" method="GET" onSubmit={e => {
                                e.preventDefault();
                                handleBtnClick();
                            }}>
                                <input type="text" name="" id="" value={searchUID} onChange={({target})=>setSearchUID(target.value)} maxLength={8} placeholder="나의 UID 숫자 8자리를 입력하세요." />
                                <button type="button" className="search_btn" onClick={handleBtnClick}>search</button>
                            </form>
                        </div>
                        <ul className="list">
                            <li>
                                <p>실시간 순위는 전일 00:00 ~ 24:00 데이터가 익일 14:00에 업데이트 됩니다.</p>
                            </li>
                        </ul>
                        <strong className="user_club">
                            {searchTxt}
                        </strong>

                        <ul className="tab_menu">
                            <li className={clubType == '3천클럽' ? "on" : ""}><button type="button" value="3천클럽" onClick={handleClick}>3천 클럽</button></li>
                            <li className={clubType == '1천클럽' ? "on" : ""}><button type="button" value="1천클럽" onClick={handleClick}>1천 클럽</button></li>
                            <li className={clubType == '5백클럽' ? "on" : ""}><button type="button" value="5백클럽" onClick={handleClick}>5백 클럽</button></li>
                            <li className={clubType == '1백클럽' ? "on" : ""}><button type="button" value="1백클럽" onClick={handleClick}>1백 클럽</button></li>
                        </ul>
                    </div>
                </div>

                <div className="tab_cont inner">
                    <div className="tab_box on">
                        <div className="club_cont">

                            {/* <!-- 수익률 --> */}
                            {clubRankList.percentList.top.length || clubRankList.percentList.other.length ? percentList(clubRankList) : ""}
                            {/* <!-- //  수익률  //--> */}


                            {/* <!-- 수익금 --> */}
                            {clubRankList.profitsList.top.length || clubRankList.profitsList.other.length ? profitsList(clubRankList) : ""}
                            {/* <!-- //  수익금  //--> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default RankingClub;

function profitsList(list) {
    return (
        <div className="money">
            <strong className="label">수익금 클럽</strong>

            <ul className="rank_box">
                {list.profitsList.top.map((itm, key) => {
                    let nickName = "";
                    if (itm.nick) {
                        nickName = itm.nick;
                    } else {
                        nickName = nickTmpName;
                    }
                    return (
                        <li className={rankTopArr[key]} key={itm.user_id}>
                            <div className="rank_info">
                                <strong>{itm.rank_profits}</strong>
                                <h4 className="club_name">{nickName}</h4>
                                <span>{handleUIDMasking(itm.user_id)}</span>
                            </div>
                            <div className="income_box">
                                <p>
                                    <span>누적수익금</span>
                                    <b className="red">{itm.accumulate_profits}원</b>
                                </p>
                                <p>
                                    <span>전일수익금</span>
                                    <b>{itm.profits}원</b>
                                </p>
                            </div>
                        </li>
                    )
                })}
            </ul>

            <div className="rank_an_list">
                <table>
                    <thead>
                        <tr>
                            <th className="num">#</th>
                            <th className="user_id">닉네임</th>
                            <th className="uid">UID</th>
                            <th className="accumu">누적수익금</th>
                            <th className="eve">전일수익금</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list.profitsList.other.map((itm, key) => {
                                let nickName = "";
                                if (itm.nick) {
                                    nickName = itm.nick;
                                } else {
                                    nickName = nickTmpName;
                                }
                                return (
                                    <tr className={key == 0 ? "first" : ""} key={itm.user_id}>
                                        <td className="num">{itm.rank_profits}</td>
                                        <td className="user_id">{nickName}</td>
                                        <td className="uid">{handleUIDMasking(itm.user_id)}</td>
                                        <td className="accumu">{itm.accumulate_profits}원</td>
                                        <td className="eve">{itm.profits}원</td>
                                    </tr>
                                )
                            })
                        }

                        {/* 
                        <tr>
                            <td className="num">10</td>
                            <td className="user_id">닉네임미설정</td>
                            <td className="uid">852231**</td>
                            <td className="accumu">20,000,000원</td>
                            <td className="eve">5,000,000원</td>
                        </tr> */}

                    </tbody>
                </table>
            </div>
        </div>
    )
}

function percentList(list) {
    return (
        <div className="percent">
            {/* <!-- 탭메뉴 확인으로 수익률 클럽옆에 괄호로 표시해놨습니다 개발하실때 지워주세요!--> */}
            <strong className="label">수익률 클럽</strong>

            <ul className="rank_box">
                {list.percentList.top.map((itm, key) => {
                    let nickName = "";
                    if (itm.nick) {
                        nickName = itm.nick;
                    } else {
                        nickName = nickTmpName;
                    }
                    return (
                        <li className={rankTopArr[key]} key={itm.user_id}>
                            <div className="rank_info">
                                <strong>{itm.rank_percent}</strong>
                                <h4 className="club_name">{nickName}</h4>
                                <span>{handleUIDMasking(itm.user_id)}</span>
                            </div>
                            <div className="income_box">
                                <p>
                                    <span>누적수익률</span>
                                    <b className="red">{itm.rateOfReturn}%</b>
                                </p>
                                <p>
                                    <span>전일수익률</span>
                                    <b>{itm.accumulate_rateOfReturn}%</b>
                                </p>
                            </div>
                        </li>
                    )
                })}
            </ul>

            <div className="rank_an_list">
                <table>
                    <thead>
                        <tr>
                            <th className="num">#</th>
                            <th className="user_id">닉네임</th>
                            <th className="uid">UID</th>
                            <th className="accumu">누적수익률</th>
                            <th className="eve">전일수익률</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                            list.percentList.other.map((itm, key) => {
                                let nickName = "";
                                if (itm.nick) {
                                    nickName = itm.nick;
                                } else {
                                    nickName = nickTmpName;
                                }
                                return (
                                    <tr className={key == 0 ? "first" : ""} key={itm.user_id}>
                                        <td className="num">{itm.rank_percent}</td>
                                        <td className="user_id">{nickName}</td>
                                        <td className="uid">{handleUIDMasking(itm.user_id)}</td>
                                        <td className="accumu">{itm.accumulate_rateOfReturn}%</td>
                                        <td className="eve">{itm.rateOfReturn}%</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
