import _ from "lodash";

const Tap = 500;

export const callOnce = (callback) => {
  return _.throttle(callback, Tap, { trailing: false });
};

export const debounceOnce = (callback) => {
  return _.debounce(callback, Tap, { leading: true, trailing: false });
};
