import $ from "jquery";


import {useEffect, useState} from 'react';

import {
    BrowserRouter,
    Routes,
    Route,
    Link
  } from "react-router-dom";


function Event(){

    useEffect(()=>{
        // $('.chk_cancle').click(function(){
        //     $('input[name="category"]').prop("checked", false);
        // })

        


    
    }, []);

    return(
       
        <div id="container" className="sub event">
            <section>
                <h2 className="ttl">이벤트</h2>
                <p className="detail_txt">Event 1. 주간 거래량 이벤트</p>
                <div className="table_wrap td_full_bg col3">
                    <table>
                        <tbody>
                            <tr>
                                <th>대상자</th>
                                <td colSpan="4" className="anth_bg">비트코인 선물 실전투자대회 모든 참가자</td>
                            </tr>
                            <tr>
                                <th rowSpan="4">이벤트 내용</th>
                                <td>주간 거래량 1,000,000 USDT 이상</td>
                                <td colSpan="3">신세계 상품권 10만원</td>
                            </tr>
                            <tr>
                                <td>주간 거래량 5,000,000 USDT 이상</td>
                                <td colSpan="3">신세계 상품권 30만원</td>
                            </tr>
                            <tr>
                                <td>주간 거래량 10,000,000 USDT 이상</td>
                                <td colSpan="3">100만원</td>
                            </tr>
                            <tr>
                                <td>주간 거래량 30,000,000 USDT 이상</td>
                                <td colSpan="3">200만원</td>
                            </tr>
                            <tr>
                                <th>수상조건</th>
                                <td colSpan="4" className="anth_bg">1주차 ~ 7주차까지 주차별 중복 수상 가능, 주차 내 중복수상 불가</td>
                            </tr>
                            <tr>
                                <th rowSpan="7">수상자 발표</th>
                                <td>1주차 수상자 발표</td>
                                <td colSpan="3">08. 03(수)</td>
                            </tr>
                            <tr>
                                <td>2주차 수상자 발표</td>
                                <td colSpan="3">08. 10(수)</td>
                            </tr>
                            <tr>
                                <td>3주차 수상자 발표</td>
                                <td colSpan="3">08. 17(수)</td>
                            </tr>
                            <tr>
                                <td>4주차 수상자 발표</td>
                                <td colSpan="3">08. 24(수)</td>
                            </tr>
                            <tr>
                                <td>5주차 수상자 발표</td>
                                <td colSpan="3">08. 31(수)</td>
                            </tr>
                            <tr>
                                <td>6주차 수상자 발표</td>
                                <td colSpan="3">09. 07(수)</td>
                            </tr>
                            <tr>
                                <td>7주차 수상자 발표</td>
                                <td colSpan="3">09. 14(수)</td>
                            </tr>
                            <tr>
                                <th>기타</th>
                                <td colSpan="4" className="anth_bg">5만원 이하 제세공과금(4.4%), 5만원 초과 제세공과금(22%) 고객 부담</td>
                            </tr>


                        </tbody>
                    </table>
                </div>
                <p className="detail_txt">Event 2. 공유하기 이벤트</p>
                <div className="table_wrap">
                    <table>
                        <tbody>
                            <tr>
                                <th>대상자</th>
                                <td>누구나 참여가능 (대회 미 참가자 참여 가능)</td>
                            </tr>
                            <tr>
                                <th>이벤트 내용</th>
                                <td>블로그, 트위터, 페이스북, 인스타그램 등 공유를 원하는 자신의 SNS로 대회 홍보게시글 공유하기 (대회 홈페이지 이벤트 페이지 참조)</td>
                            </tr>
                            <tr>
                                <th>이벤트 경품</th>
                                <td>공유한 고객 중 총 10명을 추첨하여 신세계 상품권 100만원 증정 (총 1,000만원 상당)</td>
                            </tr>
                            <tr>
                                <th>이벤트 기간</th>
                                <td>2022. 07. 11(월) ~ 2022. 08. 31(수)</td>
                            </tr>
                            <tr>
                                <th>수상자 발표</th>
                                <td>2022. 09. 14(수)</td>
                            </tr>
                            <tr>
                                <th>기타</th>
                                <td>각 SNS 종류별로 1회씩 총 4회 추첨 기회 제공, 대회 미 참가자도 참여 가능, 대회기간 중 공유한 포스트 삭제 시 수상 취소, 5만원 초과 제세공과금(22%)
                                    고객 부담</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table_wrap">
                    <table>
                        <tbody>
                            <tr> 
                                <th>참가신청 방법</th>
                                <td>아래의 SNS 별로 공유하기 버튼을 클릭하여 자신의 SNS에 대회홍보물을 공유한 뒤 공유하기 이벤트 참가 신청</td>
                            </tr>
                            <tr>
                                <th>참가신청</th>
                                <td><a href="https://forms.gle/B9uSdPyWFs9o8ixT8" className="point" target="_blank">공유하기 이벤트 참가신청 <span className="link_box point"></span></a></td>
                            </tr>
                        
                        </tbody>
                    </table>
                </div>
            </section>
            <section>
                <ul className="share_list flex fl_w">
                    <li>
                        <a href="https://blog.naver.com/mbl2022/222804230584" target="_blank"><span className="share_ico naver"></span>
                        <p>네이버 블로그로<br className="only_mo"/> 공유하기</p></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/p/Cfu5MTqJVch/?utm_source=ig_web_copy_link" target="_blank"><span className="share_ico instar"></span>
                        <p>인스타그램으로<br className="only_mo"/> 공유하기</p></a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/109964128443502/posts/109972901775958/" target="_blank"><span className="share_ico face"></span>
                        <p>페이스북으로<br className="only_mo"/> 공유하기</p></a>
                    </li>
                    <li>
                        <a href=" https://twitter.com/MBL_2022/status/1545221926867521536?s=20&t=fJKmQMvXHOiLBhgjLO3JfQ" target="_blank"><span className="share_ico twitt"></span>
                        <p>트위터로<br className="only_mo"/> 공유하기</p></a>
                    </li>
                </ul>
            </section>
        </div>
   
    )
}




export default Event;