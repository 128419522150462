//import './HeadFile.css';

import MEXC_logo        from "../assets/img/main/MEXC_logo.png";
import MBL_logo         from "../assets/img/main/MBL_logo.png";
import Sejong_logo      from "../assets/img/main/Sejong_logo.png";
import main_visual01    from "../assets/img/main/main_visual01.png";
import main_visual02    from "../assets/img/main/main_visual02.png";
import main_item01      from "../assets/img/main/main_item01.png";
import main_item02      from "../assets/img/main/main_item02.png";
import main_item03      from "../assets/img/main/main_item03.png";
import main_item04      from "../assets/img/main/main_item04.png";

import $ from "jquery";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

function Main(){

    useEffect(()=>{
       
        $(function () {
            setTimeout(function () {
                $('.main_sect01').addClass('on');
            }, 500)
    
    
            var aniDiv = document.querySelectorAll(".ani_ele");
            var aniDivArry = new Array();
    
            Array.prototype.forEach.call(aniDiv, function (ele) {
                aniDivArry.push(ele);
            });
    
            $(window).on('scroll', function () {
                var scrollTop = $(window).scrollTop(),
                    windowH = $(window).height() / 3 * 2;
    
                for (var i = 0; i < aniDivArry.length; i++) {
                    if ($(aniDivArry[i]).offset().top < scrollTop + windowH) {
    
                        $(aniDivArry[i]).addClass('on');
                        aniDivArry.splice(i, 1); // on 되면 삭제
    
                    }
                }
    
            });
    
        })
    
    }, []);

    return(
        <div id="container" className="main">
        
            <section className="main_sect01">
                {/* <div className="top_icon">
                    <div className="inner flex_c_c">
                        <a href="#!"><img src={MEXC_logo} alt="MEXC global" /></a>
                        <a href="#!"><img src={MBL_logo} alt="MBL summer 2022" /></a>
                        <a href="#!"><img src={Sejong_logo} alt="세종컨설팅스룹" /></a>
                    </div>
                </div> */}
                <div className="inner">
                    <div className="main_visual">
                        <img src={main_visual01} alt="3D 이미지" />
                        <img src={main_visual02} alt="2022 MEXC BITCOIN LEAGUE 비트코인 선물 실전 투자 대회"/>
                    </div>
                    <div className="btn_wrap">
                        <a href="https://www.mexc.com/ko-KR/register?inviteCode=mexc-mblsummer" target="_blank">계정생성</a>
                        <Link to="/member/agree" className="full_bg">참가신청</Link>
                    </div>
                </div>
            </section>
            <section className="main_sect02 ani_ele">
                <div className="inner">
                    
                    <div className="service_item ani_ele left">
                        <div className="ico">
                            <img src={main_item01} alt="" />
                        </div>
                        <div className="txt">
                            <h2 className="ttl">총 상금 1억 9,315만원에 <br/>바로 도전하세요</h2>
                            <p className="desc">2022 MBL 서머는 코인마켓캡 Exchange(거래소) 중 Spot (현물) 점수 상위 14위에 랭크된 MEXC Global 거래소와 함께합니다.
                                MEXC Global의 후원으로 국내 최대 규모의 상금을 건 비트코인 선물 실전투자대회에서 주인공이 되어보세요.
                            </p>
                        </div>
                    </div>

                    <div className="service_item ani_ele right">
                        <div className="ico">
                            <img src={main_item02} alt="" />
                        </div>
                        <div className="txt">
                            <h2 className="ttl">누구나 참여 가능한 <br/>1,000만원 추첨 이벤트</h2>
                            <p className="desc">이번 MBL 서머에서는 공유하기 이벤트를 통해 총 10명의 당첨자를 추첨하며, 당첨자에게는 100만원을 수여합니다. 100만원에 당첨되고 싶으신가요?
                                이벤트 페이지에서 대회 포스트를 나의 SNS에 공유만 하면 응모가 완료됩니다.
                            </p>
                        </div>
                    </div>


                    <div className="service_item ani_ele left">
                        <div className="ico">
                            <img src={main_item03} alt="" />
                        </div>
                        <div className="txt">
                            <h2 className="ttl">실전투자대회 기간 중 <br/>
                                수수료 10% 리워드
                            </h2>
                            <p className="desc">실전투자대회 참가자 전용 추천 코드로 비트코인 선물 거래를 진행하시면 거래하는 모든 거래에 대해 수수료 10% 리워드 를 지원합니다. 수수료를 무시하지
                                마세요. 거래액과 레버리지가 높을수록 수수료는 크게 발생합니다. 수수료 10% 리워드는 대회가 끝난 후에도 계속됩니다!
                            </p>
                        </div>
                    </div>


                    <div className="service_item ani_ele right">
                        <div className="ico">
                            <img src={main_item04} alt="" />
                        </div>
                        <div className="txt">
                            <h2 className="ttl">대회 참가자 대상 <br />
                                MEXC 슈퍼 계정 지원
                            </h2>
                            <p className="desc">대회 참가자들은 반드시 MBL 서머 전용 회원가입 링크를 통해 계정을 생성해야 합니다. 이 계정은 매 거래마다 수수료 할인 및 10% 수수료 리워드 등
                                다양하고 복합적인 혜택을 받습니다. 놀라지 마세요! 이 계정은 대회가 종료되어도 계속해서 사용할 수 있습니다.
                            </p>
                        </div>
                    
                    </div>

                </div>
            </section>


            <section className="partner_sect ani_ele">
                <div className="inner">
                    <h3>PARTNER</h3>
                <div className="partner_wrap flex_b_c fl_w">
                    <a href="#!">mbl institute</a>
                    <a href="https://www.mexc.com/ko-KR/register?inviteCode=mexc-mblsummer" target="_blank">MEXC Global</a>
                    <a href="https://t.me/tetherkr" target="_blank" >TETHER KR</a>
                    <a href="https://coinpan.com/" target="_blank"  className="white">코인판</a>
                    <a href="https://cobak.co.kr/" target="_blank"  className="white">코박</a>
                    <a href="https://www.ddengle.com/" target="_blank" >땡글닷컴</a>
                    <a href="https://www.youtube.com/channel/UCeFxGzT7D_Lx_AXGVn9RFKQ" target="_blank" >bitpro</a>
                    <a href="https://www.youtube.com/channel/UCjphUpdIBR3SaQjFviL251A" target="_blank" >코리아퓨쳐스</a>
                    <a href="https://uwal.co.kr/main/main.php" target="_blank" >uwal</a>
                    <a href="http://scg.life/" target="_blank" >세종컨설팅그룹</a>
                </div>
                </div>
            </section>


        </div>
        
        

    )
}




export default Main;
