import React,{useState, useContext,createContext,useReducer, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

import * as auth from "./auth";

import { useSelector } from "react-redux";



const Login = () => {


    const initialState = {
        authenticated: false,
        token: null
    }
   
  
    function reducer(state, action) {
        switch(action.type) {
            case 'SET_TOKEN':
                return {...state, token: action.token, authenticated: action.result};
            case 'DELETE_TOKEN':
                return { ...state, token: null, authenticated: false };
            default:
                return state;
        }
    }
  
  
    const [state, dispatch] = useReducer(reducer, initialState);
    const { authenticated } = state;
  
  
    function handleLogin(token, userId) {
  
        if (token) {
            console.log('로그인 성공!');
            alert("로그인 되었습니다.");
            navigate("/");
            dispatch({
                type: 'SET_TOKEN',
                token: token,
                result: true,
            });
            auth.setTokenToCookie(token, userId);    // cookie에 refresh_token 저장
        } else {
            console.log('로그인 실패');
            dispatch({
                type: 'SET_TOKEN',
                token: null,
                result: false,
            });
        }
    }

    const handleLogout = () => {
        dispatch({
          type: 'DELETE_TOKEN',
        });
        auth.logout();
    };
    


    const apiUrl = "https://tadmin.mbl2022.com";

    const [userId, setUserId] = useState('');
    const [userPw, setUserPw] = useState('');
    const navigate = useNavigate();

    // 로그인시 로그인페이지 접근시 리다이렉트
    const selector = useSelector((state) => state.newsState);
    useEffect(() => {
        if (selector.loginChk == "YES") {
            navigate("/");
        }
    },[selector])

    const handleButton = (e) =>{
        navigate("/member/agree");
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const frm = e.target;
        if(!userId){
            alert("아이디를 입력해주세요");
            frm.user_id.focus();
            return;
        }
        if(!userPw){
            alert("비밀번호를 입력해주세요");
            frm.user_pw.focus();
            return;
        }


        const data = new FormData();
        data.append("user_id", userId);
        data.append("user_pw", userPw);
        
        axios.post(`${apiUrl}/api/login.php`, data)
            .then((res) => {
                if (res.data.result == "YES") {
                    //alert(res.data.msg);
                    handleLogin(res.data.msg, userId);
                    return;
                } else {
                    alert(res.data.msg);
                    //setCertiChk(false);
                    return;
                }
            })

    }




    return (
        <>
        <section className="member login new_login">
            <div className="form_box_wrap">
                <form action="./login_check.php" method="post" name="loginForm" id="loginForm" onSubmit={handleSubmit} >
                    <input type="hidden" name="mode" id="mode" value="true" />
                    <input type="hidden" name="sType" id="sType" value="login" />
                    <input type="hidden" name="sns_key" id="sns_key" value="" />
                    <input type="hidden" name="user_name" id="user_name" value="" />
                    <input type="hidden" name="userEmail" id="userEmail" value="" />
                    <input type="hidden" name="gubun" id="gubun" value="" />
                    <div className="form_box">
                        <h3 className="form_ttl">대회 참가자 로그인</h3>
                        <div className="input_box">
                            <input type="text" name="user_id" id="user_id" value={userId} onChange={(e)=>{setUserId(e.target.value)}} placeholder="나의 MEXC 계정 UID 숫자 8자리를 입력하세요." maxLength="8" className="onlynum user_id_input" />
                        </div>
                        <div className="input_box input_visible">
                            <input type="password" name="user_pw" id="user_pw" onChange={(e)=>setUserPw(e.target.value)} className="user_pw_input" placeholder="패스워드를 최소 8자리 이상 입력하세요."  />
                            <button type="button" className="btn_visible">숨김표시</button>
                        </div>
                        <div className="help_guide">
                            <p>
                                패스워드를 잊어버리셨나요?
                                <Link to="/find/id">여기서 도와드릴게요</Link>
                            </p>
                        </div>
                        <div className="button_box">
                            <button type="submit" className="btn_point">로그인</button>
                            <button type="button" onClick={handleButton} className="btn_white">대회참가신청</button>
                        </div>
                    </div>
                </form>	
            </div>
        </section>
        </>
    )
}

export default Login;