import scd_202207        from "../assets/img/sub/scd_202207.svg";
import scd_202207_mo     from "../assets/img/sub/scd_202207_mo.svg";
import scd_202208        from "../assets/img/sub/scd_202208.svg";
import scd_202208_mo     from "../assets/img/sub/scd_202208_mo.svg";
import scd_202209        from "../assets/img/sub/scd_202209.svg";
import scd_202209_mo     from "../assets/img/sub/scd_202209_mo.svg";



import $ from "jquery";
import {useEffect, useState} from 'react';

import {
    BrowserRouter,
    Routes,
    Route,
    Link
  } from "react-router-dom";


function Schedule(){

    useEffect(()=>{
        // $('.chk_cancle').click(function(){
        //     $('input[name="category"]').prop("checked", false);
        // })

        


    
    }, []);

    return(
       
        <div id="container" className="sub schedule">
            <section>
                <h2 className="ttl">
                    대회일정
                </h2>
                <p className="y_m_txt">2022년 7월</p>
                <div className="schedule01">
                    <img src={scd_202207} alt="2022년 7월 스케줄" className="only_web" />
                    <img src={scd_202207_mo} alt="2022년 7월 스케줄" className="only_mo" />
                </div>
            </section>
            <section>
                <p className="y_m_txt">2022년 8월</p>
                <div className="schedule02">
                    <img src={scd_202208} alt="2022년 8월 스케줄" className="only_web" />
                    <img src={scd_202208_mo} alt="2022년 8월 스케줄" className="only_mo" />
                </div>
            </section>
            <section>
                <p className="y_m_txt">2022년 9월</p>
                <div className="schedule03">
                    <img src={scd_202209} alt="2022년 9월 스케줄" className="only_web" />
                    <img src={scd_202209_mo} alt="2022년 9월 스케줄" className="only_mo" />
                </div>
                <div className="addition_txt w_1200">
                    <p>※ 대회 기간 및 수상자 발표, 대회 상금 수여일은 주최측 및 대회 운영 상황에 따라 변동될 수 있으며, 주최측은 대회 기간 변동사항이 있을 경우 24시간 이내에 전자우편/SMS 등의 방법을 통해 대회 참가자에게 변동사항을 공지합니다.</p>
                </div>
            </section>
        </div>
   
    )
}




export default Schedule;