import React from "react";

const Policy = () => {
    return (
        <div id="container" className="sub policy">
            <section>
                <h2 className="ttl">개인정보취급방침</h2>
                <p>MBL INSTITUTE 는 2022 MBL SUMMER 실전투자대회와 관련하여 ｢개인정보보호법」 제15조제1항제1호, 제17조제1항제1호, 제23조제1호, 제24조제1항제1호에 따라 아래와 같이 개인정보의 수집⋅이용에 관하여 귀하의 동의를 얻고자 합니다.</p>
                <div className="content">
                    <ul className="list">
                        <li>개인정보 수집⋅이용 목적<br/>2022 MBL SUMMER 참가 및 시상 등을 위한 최소 정보의 수집과 이용</li>
                        <li>수집⋅이용하는 개인정보 항목<br/>성명, 연락처, MEXC Global 거래소 UID</li>
                        <li>개인정보 보유⋅이용 기간<br/>개인정보는 대회 기한이 끝난 후 1년 이내에 파기<br/>추가적으로 정보의 주체가 개인정보 삭제를 요청할 시 지체 없이 파기</li>
                        <li>동의를 거부할 권리 및 동의를 거부할 경우의 불이익<br/>위 개인정보 중 필수항목의 수집⋅이용에 관한 동의는 본 대회 진행을 위해 필수적이므로 이에 동의하셔야 이후 절차를 진행할 수 있습니다.<br/>단, 정보의 주체는 위의 개인정보 수집⋅이용에 대한 동의를 거부할 권리가 있습니다.<br/>그러나 동의를 거부할 경우 대회 참가가 불가능합니다.</li>
                        <li>취득된 개인정보는 개인정보 보호법에 의거 보호되며, 대회 담당자를 통하여 열람, 정정, 파기 요청을 할 수 있습니다.<br/>또한 개인정보는 개인정보취급방침에 따라 안전하게 보호되며 정보 주체의 명백한 동의 없이 공개 또는 제 3자에게 제공되지 않습니다.</li>
                    </ul>
                </div>
            </section>
        </div>
    )
}

export default Policy;