

import $ from "jquery";

//import mCustomScrollbar from 'malihu-custom-scrollbar-plugin';
import "jquery-mousewheel";
import "malihu-custom-scrollbar-plugin";

import { useEffect, useState, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as newsAction from "./reducer/news/action";
import NewsList from "./newsComponent/NewsList";
import { callOnce } from "../helper.js/function.helper";


function News() {
  const dispatch = useDispatch();
  const [category, setCategory] = useState(new Set());

  const selector = useSelector((state) => state.newsState);

  useEffect(() => {
    dispatch(
      newsAction.newsList.request({ url: "api/news.php", bbsCode: "all" })
    );

    function responsive() {
      var $wh = $(window).width();
      const filterWrapHeight = $(".filter_wrap").outerHeight();
      const $newsList = $(".news_list_wrap");
      if ($wh <= 850) {
        $("body").removeClass("web");
        $("body").addClass("mobile");
        $(".service_item").removeClass("right");
        $(".service_item").addClass("left");
        $newsList.css("padding-top", filterWrapHeight);
        $(".news .news_list_wrap").mCustomScrollbar("disable");
      } else {
        $("body").removeClass("mobile");
        $("body").addClass("web");
        $(".service_item:nth-of-type(even)").addClass("right");
        $(".service_item:nth-of-type(odd)").addClass("left");
        $(".news .news_list_wrap").mCustomScrollbar({
          theme: "dark-3",
        });
        $newsList.css("padding-top", 0);
      }
    }
    $(function () {
      responsive();
    });
  }, []);

  const memoList = useMemo(() => {
    let result = selector.newsList
    if(category.size !== 0) {
     result = selector.newsList.filter((element) =>
         category.has(element.board_name)
     )
    }
    return result;
  }, [category, selector.newsList]);

  const handleCategory = callOnce((res) => {
    if (category.has(res)) {
      setCategory(
        (state) => new Set([...state].filter((element) => element !== res))
      );
    } else {
      setCategory((state) => new Set([...state, res]));
    }
  });

  const handleClear = () => {
    setCategory(new Set());
  }

  return (
    <div id="container" className="news flex_b">
      <div className="news_list_wrap">
        <div className="news_list_inner">
          <div className="top">
            <p>
              결과(<span>{memoList.length}</span>)
            </p>
          </div>
          <div className="news_list">
            <ul className="flex fl_w">
              <NewsList list={memoList} />
            </ul>
          </div>
        </div>
      </div>

      <div className="filter_wrap">
        <div className="top">
          <p className="filter_ico">
            필터(<span>{category.size}</span>){" "}
          </p>
          <button type="button" className="chk_cancle point"
            onClick={handleClear}
          >
            전체 해제
          </button>
        </div>

        <div className="filter_list">
          <p>
            카테고리(<span>6</span>)
          </p>
          <ul>
            <li>
              <input
                type="checkbox"
                name="category"
                id="notice"
                checked={category.has("공지사항")}
                onChange={(event) => {
                  handleCategory("공지사항");
                }}
              />
              <label htmlFor="notice">공지사항</label>
            </li>
            <li>
              <input
                type="checkbox"
                name="category"
                id="news"
                checked={category.has("뉴스")}
                onChange={(event) => {
                  handleCategory("뉴스");
                }}
              />
              <label htmlFor="news">뉴스</label>
            </li>
            <li>
              <input
                type="checkbox"
                name="category"
                id="guide"
                checked={category.has("가이드")}
                onChange={(event) => {
                  handleCategory("가이드");
                }}
              />
              <label htmlFor="guide">가이드</label>
            </li>
            <li>
              <input
                type="checkbox"
                name="category"
                id="winner"
                checked={category.has("수상자 발표")}
                onChange={(event) => {
                  handleCategory("수상자 발표");
                }}
              />
              <label htmlFor="winner">수상자 발표</label>
            </li>

            <li>
              <input
                type="checkbox"
                name="category"
                id="event"
                checked={category.has("이벤트")}
                onChange={(event) => {
                  handleCategory("이벤트");
                }}
              />
              <label htmlFor="event">이벤트</label>
            </li>
            <li>
              <input
                type="checkbox"
                name="category"
                id="etc"
                checked={category.has("기타")}
                onChange={(event) => {
                  handleCategory("기타");
                }}
              />
              <label htmlFor="etc">기타</label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default News;
