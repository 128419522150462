const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAIL = "FAIL";
const INIT = "INIT";

const createReducer = (initialState, handlerMap) => {
  return (state = initialState, action) => {
    const handler = handlerMap[action.type];
    if (handler) {
      return handler(state, action);
    } else {
      return state;
    }
  };
};

const typeCreator = (type) => {
  const types = [REQUEST, SUCCESS, FAIL, INIT];
  return types.reduce((acc, cur) => {
    acc[cur] = `${type}_${cur}`;
    return acc;
  }, {});
};

const actionCreator = (type, payload = {}) => {
  return { type, payload };
};

const createActionObject = (type) => {
  return {
    request: (data) => actionCreator(type[REQUEST], data),
    success: (data) => actionCreator(type[SUCCESS], data),
    fail: (data) => actionCreator(type[FAIL], data),
    init: (data) => actionCreator(type[INIT], data),
  };
};

export { createReducer, typeCreator, actionCreator, createActionObject };
