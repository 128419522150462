//import logo from './logo.svg';
import logo from "./assets/img/favicon.ico";
//import './App.css';

// import { BrowserRouter as Routes, Route } from "react-router-dom";
/*
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
*/
import { useState, useEffect, useReducer  } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import "./assets/css/jquery.mCustomScrollbar.css";
import "./assets/css/slick.css";
import "./assets/css/notosanskr.css";
import "./assets/css/reset.css";
import "./assets/css/custom.css";
import "./assets/css/common.css";

import "./assets/css/main.css";
import "./assets/css/sub.css";
import "./assets/css/mobile.css";
import "./assets/css/slick.css";
import "./assets/css/parkLe.css";

import "./assets/css/login.css";

/* import './assets/css/HeadFile.css'; */

import HeadFile    from "./components/HeadFile";
import Main        from "./components/Main";
import TopCont     from "./components/topCont";
import Footer      from "./components/footer";
import News        from "./components/news";
import NewsView    from "./components/newsView";
import Overview    from "./components/overview";
import Participate from "./components/participate";
import Schedule    from "./components/schedule";
import Event       from "./components/event";
import MemberAgree from "./components/MemberAgree";
import MemberReg   from "./components/MemberReg";
import Login       from "./components/Login";
import Logout      from "./components/Logout";
import FindId      from "./components/FindId";
import FindPw      from "./components/FindPw";
import PopUpPc from './components/PopUpPC';
import PopUpMobile from './components/PopUpMobile';
import Mypage from "./components/Mypage";
import Policy from "./components/Policy";
import * as auth from "./components/auth";
import Cookies from 'universal-cookie';
import ScrollTop from "./components/ScrollTop";
import Ranking from "./components/Ranking";


import { useDispatch, useSelector } from "react-redux";
import * as newsAction from "./components/reducer/news/action";
import NickCheck from "./components/NickCheck";
import RankingClub from "./components/RankingClub";


/*
 <>
              <div id="wrap">
                <HeadFile />
                <TopCont />
                <Main />
                <Footer />
              </div>
            </>
*/

function App() {

  const cookies = new Cookies();

  const dispatch = useDispatch();
  const selector = useSelector((state) => state.newsState);
  


  const [windowSize, setWindowSize] = useState({
      width: window.innerWidth
  })
  const handleResize = () => {
      setWindowSize({
          width: window.innerWidth
      });
      
  }

  const userToken = cookies.get('userToken');
  //console.log(userToken);


  useEffect(() => { 

      dispatch(
        newsAction.loginChk.request( { token: userToken } )
      );

      
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);

         
      }
  }, [dispatch,userToken])


   //let result = selector.loginChk.isFetching;

   //let result = selector.loginChk;
      // console.log( "userToken : " + userToken + " / selector : " + result );
   //console.log( "userToken : " + userToken + " / selector : " + selector.loginChk );
   



  
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div id="wrap">
              <ScrollTop />
              {windowSize.width > 750 ?
                <PopUpPc />
                :
                <PopUpMobile />
              }
              <HeadFile />
              <TopCont />
              <Main />
              <Footer />
            </div>
          }
        />

        <Route
          path="/news"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <News />
              <Footer />
            </div>
          }
        />

        <Route
          path="/newsView"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <NewsView />
              <Footer />
            </div>
          }
        />

        <Route
          path="/newsView/:bbs_idx"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <NewsView />
              <Footer />
            </div>
          }
        />

        <Route
          path="/overview"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <TopCont />
              <Overview />
              <Footer />
            </div>
          }
        />

        <Route
          path="/participate"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <TopCont />
              <Participate />
              <Footer />
            </div>
          }
        />

        <Route
          path="/schedule"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <TopCont />
              <Schedule />
              <Footer />
            </div>
          }
        />

        <Route
          path="/event"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <TopCont />
              <Event />
              <Footer />
            </div>
          }
        />

        <Route
          path="/member/agree"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              {/* <TopCont /> */}
              <MemberAgree />
              <Footer />
            </div>
          }
        />

        <Route
          path="/member/reg"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <MemberReg />
              <Footer />
            </div>
          }
        />

        <Route
          path="/login"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <Login />
              <Footer />
            </div>
          }
        />

        <Route
          path="/logout"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <Logout />
              <Footer />
            </div>
          }
        />

        <Route
          path="/find/id"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <FindId />
              <Footer />
            </div>
          }
        />

        <Route
          path="/find/pw"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <FindPw />
              <Footer />
            </div>
          }
        />

        <Route
          path="/mypage"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <Mypage />
              <Footer />
            </div>
          }
        />
        <Route
          path="/policy"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <Policy />
              <Footer />
            </div>
          }
        />
        {/* 랭킹[2022/08/19일 이전] */}
        {/* <Route
          path="/ranking"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <Ranking />
              <Footer />
            </div>
          }
        /> */}
        {/* 랭킹[2022/08/19일 이후] */}
        <Route
          path="/ranking"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <TopCont />
              <RankingClub />
              <Footer />
            </div>
          }
        />
        <Route
          path="/nickCheck"
          element={
            <div id="wrap">
              <ScrollTop />
              <HeadFile />
              <NickCheck />
              <Footer />
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
